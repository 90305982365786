import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

export default function Loading(props) {
    const canvasRef = useRef(null)

    useEffect(() => {
        lottie.loadAnimation({
            container: canvasRef.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: '/animation/loading_animation_lottie.json'
        })
    }, [])

    return (
        <div className="loading-animation" ref={canvasRef} />
    )
}