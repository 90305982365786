import React from 'react';

export default function IconLogo() {
    return (
        <svg width="156" height="40" viewBox="0 0 156 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1210_8065)">
                <path d="M45.7599 35.6501L45.1899 36.9101H43.8799L47.1299 30.3201L50.3999 36.9101H49.0899L48.4899 35.6501H45.7599ZM46.2199 34.6501H47.9999L47.0899 32.7101L46.2199 34.6501Z" fill="white" fillOpacity="0.3" />
                <path d="M60 34.5901V30.5901H61.2V34.5901C61.187 34.7826 61.213 34.9757 61.2766 35.1579C61.3402 35.3401 61.44 35.5075 61.57 35.6501C61.8452 35.8923 62.2038 36.0178 62.57 36.0001C62.9339 36.0145 63.2903 35.8934 63.57 35.6601C63.7128 35.5198 63.8248 35.3513 63.8988 35.1654C63.9729 34.9794 64.0073 34.7801 64 34.5801V30.5801H65.2V34.5801C65.2004 34.9428 65.1326 35.3024 65 35.6401C64.866 35.948 64.6683 36.224 64.42 36.4501C63.9172 36.8988 63.2638 37.1416 62.59 37.1301C61.9226 37.1395 61.2762 36.8966 60.78 36.4501C60.5371 36.2198 60.3461 35.9402 60.22 35.6301C60.0764 35.3021 60.0015 34.9481 60 34.5901Z" fill="white" fillOpacity="0.3" />
                <path d="M78.6199 37.0601C78.1803 37.0577 77.7455 36.9694 77.3399 36.8001C76.9612 36.6348 76.6208 36.3931 76.3399 36.0901C76.0364 35.8074 75.7917 35.4675 75.6199 35.0901C75.4513 34.6842 75.363 34.2495 75.3599 33.8101C75.3587 33.3691 75.4507 32.933 75.6299 32.5301C75.7907 32.1514 76.0292 31.8108 76.3299 31.5301C76.6311 31.2153 76.9926 30.9645 77.393 30.7927C77.7933 30.6209 78.2242 30.5315 78.6599 30.5301C79.3328 30.5036 79.994 30.7122 80.5299 31.1201V32.6101C80.2992 32.3397 80.0125 32.1227 79.6896 31.9742C79.3667 31.8257 79.0153 31.7492 78.6599 31.7501C78.3787 31.7459 78.0995 31.7976 77.8386 31.9024C77.5776 32.0071 77.3401 32.1627 77.1399 32.3601C76.9416 32.547 76.7837 32.7725 76.6757 33.0227C76.5678 33.2729 76.5121 33.5426 76.5121 33.8151C76.5121 34.0876 76.5678 34.3572 76.6757 34.6074C76.7837 34.8576 76.9416 35.0831 77.1399 35.2701C77.5497 35.6606 78.0938 35.8789 78.6599 35.8801C79.0129 35.9162 79.3693 35.8628 79.6961 35.7247C80.023 35.5866 80.3097 35.3684 80.5299 35.0901V36.5101C79.966 36.8877 79.2983 37.08 78.6199 37.0601V37.0601Z" fill="white" fillOpacity="0.3" />
                <path d="M95.8102 31.7801H93.7202V36.9101H92.5702V31.7801H90.4902V30.6101H95.8102V31.7801Z" fill="white" fillOpacity="0.3" />
                <path d="M107.07 30.6101V36.9101H105.86V30.6101H107.07Z" fill="white" fillOpacity="0.3" />
                <path d="M123.41 32.47C123.729 33.2899 123.729 34.2 123.41 35.02C123.244 35.3931 123.01 35.7322 122.72 36.02C122.432 36.3146 122.093 36.5551 121.72 36.7299C121.323 36.8932 120.899 36.9781 120.47 36.9799C120.041 36.9771 119.617 36.8923 119.22 36.7299C118.844 36.5591 118.505 36.3181 118.22 36.02C117.928 35.7339 117.694 35.3944 117.53 35.02C117.364 34.6135 117.279 34.1788 117.28 33.74C117.28 33.3044 117.364 32.8729 117.53 32.47C117.685 32.0733 117.92 31.7126 118.22 31.41C118.505 31.1118 118.844 30.8708 119.22 30.7C119.617 30.5376 120.041 30.4528 120.47 30.45C120.899 30.4518 121.323 30.5367 121.72 30.7C122.097 30.8679 122.437 31.1093 122.72 31.41C123.017 31.7145 123.252 32.0747 123.41 32.47V32.47ZM122.51 33.74C122.52 33.4655 122.473 33.1919 122.372 32.9364C122.272 32.6808 122.119 32.4487 121.925 32.2545C121.731 32.0603 121.499 31.9081 121.243 31.8076C120.988 31.707 120.714 31.6602 120.44 31.67C120.033 31.678 119.638 31.805 119.303 32.0353C118.968 32.2656 118.707 32.5891 118.554 32.9658C118.401 33.3425 118.362 33.7558 118.441 34.1547C118.52 34.5535 118.714 34.9204 119 35.2099C119.289 35.502 119.659 35.7012 120.062 35.7821C120.466 35.863 120.884 35.822 121.264 35.6643C121.643 35.5066 121.968 35.2393 122.195 34.8966C122.422 34.5539 122.542 34.1512 122.54 33.74H122.51Z" fill="white" fillOpacity="0.3" />
                <path d="M139.44 37.2001L135 33.0001V37.0001H133.83V30.3401L138.31 34.6001V30.6001H139.49L139.44 37.2001Z" fill="white" fillOpacity="0.3" />
                <path d="M150.31 36.69C150.117 36.6011 149.941 36.4792 149.79 36.33V34.82C149.944 34.9867 150.111 35.1405 150.29 35.28C150.517 35.4492 150.766 35.5871 151.03 35.69C151.352 35.803 151.69 35.8605 152.03 35.86C152.382 35.8747 152.73 35.7842 153.03 35.6C153.122 35.552 153.202 35.4824 153.261 35.397C153.32 35.3116 153.358 35.2131 153.37 35.11C153.37 34.9983 153.337 34.8891 153.275 34.7964C153.212 34.7038 153.124 34.6319 153.02 34.59C152.699 34.4557 152.363 34.3584 152.02 34.3V34.3C151.501 34.2361 150.998 34.0732 150.54 33.82C150.301 33.6859 150.104 33.4887 149.97 33.25C149.817 32.9681 149.741 32.6507 149.75 32.33C149.743 32.0491 149.809 31.7711 149.942 31.5237C150.076 31.2764 150.272 31.0682 150.51 30.92C150.966 30.6357 151.494 30.4897 152.03 30.5C152.587 30.4789 153.14 30.5957 153.64 30.84C153.806 30.9125 153.953 31.022 154.07 31.16V32.59C153.806 32.2994 153.48 32.0707 153.117 31.9203C152.754 31.7699 152.363 31.7015 151.97 31.72C151.729 31.7168 151.489 31.7681 151.27 31.87C151.165 31.9008 151.072 31.9636 151.004 32.0496C150.936 32.1355 150.896 32.2405 150.89 32.35C150.886 32.4727 150.921 32.5936 150.989 32.6958C151.057 32.7979 151.155 32.8763 151.27 32.92C151.616 33.0676 151.979 33.1718 152.35 33.23C152.857 33.3043 153.349 33.4561 153.81 33.68C154.04 33.8062 154.23 33.993 154.36 34.22C154.507 34.4752 154.579 34.7659 154.57 35.06C154.578 35.3857 154.506 35.7084 154.36 36C154.222 36.255 154.023 36.4716 153.78 36.63C153.289 36.9442 152.713 37.1011 152.13 37.08C151.501 37.1012 150.876 36.9673 150.31 36.69V36.69Z" fill="white" fillOpacity="0.3" />
                <path d="M61.1099 24.0001H41.8599L51.4599 4.56006L61.1099 24.0001ZM55.7199 20.7801L51.4599 11.6801L47.2199 20.7801H55.7199Z" fill="white" fillOpacity="0.3" />
                <path d="M76.5401 5.83005C77.0341 6.06231 77.4976 6.35451 77.9201 6.70005V11.19C77.2176 10.4034 76.3665 9.76337 75.4157 9.30686C74.4649 8.85035 73.4333 8.58635 72.3801 8.53005C70.7236 8.52191 69.1285 9.15638 67.9301 10.3C67.3593 10.8654 66.9061 11.5382 66.5969 12.2797C66.2876 13.0212 66.1284 13.8166 66.1284 14.62C66.1284 15.4234 66.2876 16.2189 66.5969 16.9604C66.9061 17.7019 67.3593 18.3747 67.9301 18.94C68.514 19.4967 69.1909 19.9469 69.9301 20.27C70.696 20.5793 71.5141 20.7389 72.3401 20.74C73.4031 20.7308 74.4535 20.5099 75.4301 20.09L75.5301 20.04V14.67H79.1401V21.53H79.0901C78.6115 22.0985 78.0522 22.5937 77.4301 23C75.9022 23.9053 74.1559 24.3756 72.3801 24.36C71.0871 24.3527 69.8075 24.0981 68.6101 23.61C66.2904 22.6008 64.4394 20.7498 63.4301 18.43C62.9347 17.2401 62.6797 15.964 62.6797 14.675C62.6797 13.3861 62.9347 12.11 63.4301 10.92C63.9276 9.76015 64.6401 8.70491 65.5301 7.81005C67.3523 5.99995 69.8117 4.97669 72.3801 4.96005C73.8147 4.93217 75.2369 5.22961 76.5401 5.83005V5.83005Z" fill="white" fillOpacity="0.3" />
                <path d="M88.7501 8.84011L91.0001 12.4801H95.0001V16.0001H87.0801V20.5001H96.7001V24.0001H83.6201V12.4801H86.8701L83.6201 7.09011V5.36011H96.7001V8.84011H88.7501Z" fill="white" fillOpacity="0.3" />
                <path d="M117.86 24.8201L104.6 12.2701V24.0001H101.15V4.58008L114.38 17.1601V5.36008H117.86V24.8201Z" fill="white" fillOpacity="0.3" />
                <path d="M132 24.3C130.709 24.295 129.432 24.0368 128.24 23.54C127.083 23.0528 126.033 22.3426 125.15 21.45C124.254 20.575 123.535 19.5362 123.03 18.39C122.535 17.1943 122.277 15.914 122.27 14.62C122.264 13.3216 122.533 12.0366 123.06 10.85C123.53 9.69371 124.231 8.6456 125.12 7.76999C126.02 6.862 127.092 6.14236 128.274 5.65304C129.455 5.16372 130.722 4.91453 132 4.91999C133.984 4.83909 135.934 5.44679 137.52 6.63999V11.06C136.843 10.2557 135.998 9.60959 135.044 9.16728C134.091 8.72497 133.051 8.49719 132 8.49999C131.173 8.49149 130.353 8.64609 129.586 8.95496C128.819 9.26382 128.12 9.72088 127.53 10.3C126.954 10.8592 126.496 11.5284 126.183 12.2679C125.871 13.0074 125.709 13.8021 125.709 14.605C125.709 15.4079 125.871 16.2026 126.183 16.9421C126.496 17.6815 126.954 18.3507 127.53 18.91C128.734 20.0585 130.336 20.6964 132 20.69C136.13 20.69 137.52 18.36 137.52 18.36V22.57C135.902 23.7005 133.974 24.3046 132 24.3V24.3ZM133.68 14.46C133.672 14.9278 133.482 15.3739 133.149 15.7029C132.816 16.0318 132.368 16.2174 131.9 16.22C131.657 16.2383 131.413 16.2062 131.183 16.1258C130.953 16.0454 130.742 15.9184 130.564 15.7527C130.385 15.587 130.242 15.3863 130.145 15.163C130.047 14.9397 129.997 14.6986 129.997 14.455C129.997 14.2113 130.047 13.9703 130.145 13.747C130.242 13.5237 130.385 13.3229 130.564 13.1573C130.742 12.9916 130.953 12.8646 131.183 12.7841C131.413 12.7037 131.657 12.6717 131.9 12.69C132.136 12.686 132.369 12.7288 132.588 12.8158C132.807 12.9029 133.006 13.0325 133.175 13.1971C133.343 13.3617 133.477 13.5581 133.569 13.7749C133.661 13.9917 133.709 14.2245 133.71 14.46H133.68Z" fill="white" fillOpacity="0.3" />
                <path d="M147.12 8.84011L149.34 12.4801H153.34V16.0001H145.47V20.5001H155.09V24.0001H142V12.4801H145.25L142 7.09011V5.36011H155.08V8.84011H147.12Z" fill="white" fillOpacity="0.3" />
                <path d="M0 23.6C0 16.89 2.86 7 7.57 0C8.19353 2.1444 9.51693 4.01838 11.3293 5.32325C13.1416 6.62813 15.3386 7.28881 17.57 7.2C19.8014 7.28881 21.9984 6.62813 23.8107 5.32325C25.6231 4.01838 26.9465 2.1444 27.57 0C32.27 7 35.13 16.89 35.13 23.6C35.13 35 27.27 40 17.57 40C7.87 40 0 35 0 23.6ZM17.57 34.13C23.89 34.13 28.62 30.66 29.01 24.39C29.46 17.09 27.61 14.59 26.16 13.02C24.71 11.45 22.78 11.45 21.27 13.24C20.1 14.63 19.93 17.62 20.54 19.59C19.938 19.6686 19.3264 19.5589 18.7893 19.276C18.2521 18.9931 17.8157 18.5509 17.54 18.01C17.2576 18.5452 16.82 18.9825 16.2846 19.2645C15.7492 19.5464 15.1411 19.6599 14.54 19.59C15.15 17.59 14.97 14.59 13.8 13.24C12.35 11.45 10.47 11.41 9 13C7.53 14.59 5.67 17.09 6.13 24.39C6.51 30.66 11.25 34.13 17.57 34.13ZM20.94 23.23C20.94 21.35 14.19 21.35 14.19 23.23C14.165 23.6887 14.2338 24.1476 14.3921 24.5788C14.5505 25.01 14.795 25.4044 15.1109 25.7379C15.4267 26.0714 15.8072 26.3371 16.2292 26.5186C16.6511 26.7002 17.1057 26.7938 17.565 26.7938C18.0243 26.7938 18.4789 26.7002 18.9008 26.5186C19.3228 26.3371 19.7033 26.0714 20.0191 25.7379C20.335 25.4044 20.5795 25.01 20.7379 24.5788C20.8962 24.1476 20.965 23.6887 20.94 23.23ZM12.43 19.29C12.43 18.29 11.99 17.54 11.43 17.54C10.87 17.54 10.43 18.32 10.43 19.29C10.43 20.26 10.87 21.04 11.43 21.04C11.99 21.04 12.43 20.25 12.43 19.29V19.29ZM24.67 19.29C24.67 18.29 24.23 17.54 23.67 17.54C23.11 17.54 22.67 18.32 22.67 19.29C22.67 20.26 23.11 21.04 23.67 21.04C24.23 21.04 24.67 20.25 24.67 19.29V19.29ZM18.8 13.07L17.57 10.18L16.33 13.07L17.57 16L18.8 13.07Z" fill="white" fillOpacity="0.3" />
            </g>
            <defs>
                <clipPath id="clip0_1210_8065">
                    <rect width="155.07" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}