import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-navi';
import LanguageSelect from "../../components/shared/languageSelect";
import IconGit from '../../components/icon/git';
import IconTwitter from '../../components/icon/twitter';
import IconDiscord from '../../components/icon/discord';
import IconLogo from '../../components/icon/logo';
import { LINK } from '../../utils/type';

const SNS_LINK_LIST = [
    { icon: <IconGit />, href: LINK.githubURL },
    { icon: <IconTwitter />, href: LINK.twitterURL },
    { icon: <IconDiscord />, href: LINK.discordURL }
]

export default function Footer(props) {
    const { t } = useTranslation()

    return (
        <div className="footer">
            <div className="footer-body">
                <div className="footer-navi">
                    <div className="language-logo-container">
                        <LanguageSelect />
                        <Link href="/" className="logo-link">
                            <IconLogo className="icon" />
                        </Link>
                    </div>
                    <div>
                        {SNS_LINK_LIST.map((link, idx) =>
                            <a className="btn-icon disabled-ripple" key={idx} href={link.href} target="_blank" rel="noopener noreferrer">
                                {link.icon}
                            </a>
                        )}
                    </div>
                </div>
                <div className="footer-copyright">
                    <span className="copyright-text">Copyright © 2021-2023 Heimdal Holdings Corp.&nbsp;</span>
                    <span>
                        <span className="copyright-text"> All rights reserved |&nbsp;</span>
                        <a className="copyright-link" href={LINK.contactMail}>{t("Contact")}</a>
                    </span>
                </div>
            </div>
        </div>
    )
}