import React, { useState, useEffect, useRef } from 'react';
import { useNavigation } from 'react-navi';
import { Gavel } from '@material-ui/icons';
import { Modal } from '@material-ui/core';
import SignIn from '../../views/authorize/SignIn';
import InfoButton from './topbarInfoButton';
import Account from './topbarAccount';
import Wallet from './topbarWallet';
import { PATH } from '../../utils/type';
import { SIGNIN_TYPE } from '../../utils/type';

export default function TopbarInfo(props) {
    const { wallets, userService, currentUser, currentWallet, currentPathname } = props
    const [isSignInOpen, setIsSignInOpen] = useState(false)
    const [isWalletOpen, setIsWalletOpen] = useState(false)
    const [isAccountOpen, setIsAccountOpen] = useState(false)
    const navigation = useNavigation()
    const walletBtnRef = useRef(null)
    const accountBtnRef = useRef(null)
    const walletMenuRef = useRef(null)
    const accountMenuRef = useRef(null)

    useEffect(() => {
        const onMenuClose = (event) => {
            const isWalletMenuClicked = walletMenuRef.current && walletMenuRef.current.contains(event.target)
            const isAccountMenuClicked = accountMenuRef.current && accountMenuRef.current.contains(event.target)
            const isWalletBtnClicked = walletBtnRef.current && walletBtnRef.current.contains(event.target)
            const isAccountBtnClicked = accountBtnRef.current && accountBtnRef.current.contains(event.target)

            if (!isWalletBtnClicked && !isWalletMenuClicked) {
                setIsWalletOpen(false)
            }
            if (!isAccountBtnClicked && !isAccountMenuClicked) {
                setIsAccountOpen(false)
            }
        }

        document.addEventListener('click', onMenuClose)
        return () => {
            document.removeEventListener('click', onMenuClose)
        }
    }, [])

    return <div className="topbar-info">
        <InfoButton
            currentUser={currentUser}
            currentWallet={currentWallet}
            isSignInOpen={isSignInOpen}
            setIsSignInOpen={setIsSignInOpen}
        />
        <Wallet
            userService={userService}
            setIsSignInOpen={setIsSignInOpen}
            currentUser={currentUser}
            isWalletOpen={isWalletOpen}
            walletMenuRef={walletMenuRef}
            currentWallet={currentWallet}
            wallets={wallets}
            isAccountOpen={isAccountOpen}
            currentPathname={currentPathname}
            walletBtnRef={walletBtnRef}
            setIsWalletOpen={setIsWalletOpen}
        />
        <Account
            currentUser={currentUser}
            accountBtnRef={accountBtnRef}
            accountMenuRef={accountMenuRef}
            setIsSignInOpen={setIsSignInOpen}
            isAccountOpen={isAccountOpen}
            setIsAccountOpen={setIsAccountOpen}
            userService={userService}
        />
        <CreateAuction
            ignore={!currentWallet}
            navigation={navigation}
        />
        <Modal
            className="modal arrow-modal"
            open={isSignInOpen}
            onClose={() => setIsSignInOpen(false)}
        >
            <div>
                <SignIn
                    userService={userService}
                    setIsSignInOpen={setIsSignInOpen}
                    signInType={SIGNIN_TYPE.MODAL}
                />
            </div>
        </Modal>
    </div >
}
function CreateAuction(props) {
    const { ignore, navigation } = props

    if (ignore) {
        return null
    }

    return <button
        className="create-auction-btn"
        onClick={() => navigation.navigate(PATH.SELECT_ASSET)}
    >
        <Gavel className="icon" />
    </button>
}