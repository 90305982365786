import React, { useState, useRef, useEffect } from 'react';
import { Manager, Reference } from 'react-popper';
import { WarningRounded, Error, Remove, Add, Visibility, VisibilityOff } from '@material-ui/icons';
import Dropdown from './dropdown';
import Button, { BTN_VARIANT } from './button';
import IconMana from '../icon/mana';
import { COLOR, INPUT_TYPE } from '../../utils/type';
import { Tooltip } from '../shared/tooltip';

const HelpIcon = <Error className="icon input-icon help-icon" />
const ErrorIcon = <WarningRounded className="icon input-icon error-icon" />

export default function Input(props) {
    const { inputType, defaultValue, errorMessage, helpMessage, onMouseEnter, onMouseLeave, onFocus, dropdownInputType } = props
    const [inputValue, setInputValue] = useState(defaultValue || "")
    const [touched, setTouched] = useState(false)
    const [focus, setFocus] = useState(false)
    const [hover, setHover] = useState(false)

    useEffect(() => {
        setTouched(false)
        setInputValue(defaultValue)
    }, [defaultValue])

    const handleFocus = () => {
        onFocus && onFocus()
        setFocus(true)
    }

    const handleBlur = () => {
        setFocus(false)
        setTouched(true)
    }

    const handleHover = (isHover) => {
        if (isHover) {
            onMouseEnter && onMouseEnter()
        }
        else {
            onMouseLeave && onMouseLeave()
        }
        setHover(isHover)
    }

    const states = {
        inputClassName: `input ${helpMessage ? 'with-helper' : ''} ${errorMessage ? 'error' : ''} ${props.className} `,
        inputValue: inputValue, setInputValue: setInputValue,
        touched: touched, setTouched: setTouched,
        focus: focus, setFocus: setFocus,
        hover: hover, setHover: setHover,
        handleFocus: handleFocus,
        onBlur: handleBlur,
        onHover: handleHover,
    }

    switch (inputType) {
        case INPUT_TYPE.PASSWORD:
            return <PasswordInput {...states} {...props} />
        case INPUT_TYPE.NUMBER:
        case INPUT_TYPE.NUMBER_WITH_HME:
            return <NumberInput {...states} {...props} />
        case INPUT_TYPE.NUMBER_WITH_OPERATION:
            return <NumberInputWithOperator {...states} {...props} />
        case INPUT_TYPE.WITH_DROPDOWN:
            let InputField
            if (dropdownInputType === INPUT_TYPE.NUMBER) {
                InputField = <NumberInput placement="bottom" {...states} {...props} />
            }
            else {
                InputField = <TextInput placement="bottom" {...states} {...props} />
            }
            return (
                <div className="with-dropdown-wrapper">
                    <>{InputField}</>
                    <Dropdown {...props.dropdownProps} />
                </div>
            )
        case INPUT_TYPE.TEXTAREA:
            return <TextArea {...states} {...props} />
        case INPUT_TYPE.RESIZABLE_TEXTAREA:
            return <ResizableTextArea {...states} {...props} />
        default:
            return <TextInput {...states} {...props} />
    }
}

const InputInner = (props) => {
    const { onHover, hover, focus, children, style } = props

    return (
        <Reference>
            {({ ref }) => (
                <div ref={ref} className={'input-inner' + (focus ? ' isFocus' : '') + (hover ? ' isHover' : '')}
                    style={style}
                    onMouseEnter={() => onHover(true)}
                    onMouseLeave={() => onHover(false)}
                >
                    <div className="input-overlay" />
                    {children}
                </div>
            )}
        </Reference>
    )
}

const TextInput = (props) => {
    const { id, inputClassName, label, placeholder, inputValue, setInputValue, hover, focus,
        handleFocus, onHover, onBlur, onChange, onSubmit,
        hasHelper, helpMessage, placement,
        hasError, errorMessage, disabled } = props

    const handleChange = (e) => {
        const value = e.target.value
        setInputValue(value)
        onChange(e)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onSubmit && onSubmit()
        }
    }

    return (
        <Manager>
            <div className={`input-outer  ${disabled ? 'disabled' : ''}`}>
                {label && <span className={"input-label"}>{label}</span>}
                <InputInner
                    onHover={onHover}
                    hover={hover}
                    focus={focus}
                >
                    <input
                        type={INPUT_TYPE.DEFAULT}
                        {...id ? { id: id } : {}}
                        className={inputClassName}
                        placeholder={placeholder}
                        value={inputValue}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={onBlur}
                        onKeyDown={handleKeyDown}
                        disabled={disabled}
                    />
                    {HelpIcon}
                    {ErrorIcon}
                </InputInner>
            </div>
            <Tooltip
                placement={placement}
                helpMessage={helpMessage}
                errorMessage={errorMessage}
                hasError={hasError}
                hasHelper={hasHelper}
            />
        </Manager>
    )
}

const PasswordInput = (props) => {
    const { id, inputClassName, label, placeholder, inputValue, setInputValue, hover, focus,
        handleFocus, onHover, onBlur, onChange, onSubmit, hasHelper, helpMessage, placement,
        hasError, errorMessage, disabled } = props
    const [showPassword, setShowPassword] = useState(false)
    const inputRef = useRef(null)

    const handleChange = (e) => {
        const value = e.target.value
        setInputValue(value)
        onChange(e)
    }

    const togglePasswordDisplay = () => {
        setShowPassword(prev => !prev)
        if (inputRef.current) {
            inputRef.current.focus()
            inputRef.current.selectionStart = inputRef.current.selectionEnd = inputRef.current.value.length
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onSubmit && onSubmit()
        }
    }

    return (
        <Manager>
            <div className={`input-outer  ${disabled ? 'disabled' : ''}`}>
                {label && <span className={"input-label"}>{label}</span>}
                <InputInner
                    onHover={onHover}
                    hover={hover}
                    focus={focus}
                >
                    <input
                        ref={inputRef}
                        {...id ? { id: id } : {}}
                        className={`${inputClassName} password`}
                        type={showPassword ? INPUT_TYPE.DEFAULT : INPUT_TYPE.PASSWORD}
                        value={inputValue}
                        placeholder={placeholder}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={onBlur}
                        onKeyDown={handleKeyDown}
                        disabled={disabled}
                    />
                    {HelpIcon}
                    {ErrorIcon}
                    <Button
                        className="btn-showPassword"
                        variant={BTN_VARIANT.ICON}
                        color={COLOR.PRIMARY}
                        onClick={togglePasswordDisplay}
                        disabled={disabled}
                        tabIndex="-1"
                    >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                    </Button>
                </InputInner>
            </div>
            <Tooltip
                placement={placement}
                helpMessage={helpMessage}
                errorMessage={errorMessage}
                hasError={hasError}
                hasHelper={hasHelper}
            />
        </Manager>
    )
}

const NumberInput = (props) => {
    const { id, inputType, inputClassName, label, placeholder, inputValue, setInputValue, hover, focus,
        onHover, onFocus, onBlur, onChange, onSubmit, hasHelper, helpMessage, placement,
        hasError, errorMessage, disabled
    } = props

    const handleChange = (e) => {
        const value = e.target.value
        // TODO: Revisit this
        if (/^(0|[1-9]\d*)(\.\d*)?$/.test(value)) {
            setInputValue(value)
            onChange(e)
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onSubmit && onSubmit()
        }
    }

    return (
        <Manager>
            <div className={`input-outer ${disabled ? 'disabled' : ''}`}>
                {label && <span className={"input-label"}>{label}</span>}
                <InputInner
                    onHover={onHover}
                    hover={hover}
                    focus={focus}
                >
                    <input
                        {...id ? { id: id } : {}}
                        className={inputClassName}
                        type={INPUT_TYPE.DEFAULT}
                        value={inputValue}
                        placeholder={placeholder}
                        onChange={handleChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onKeyDown={handleKeyDown}
                        disabled={disabled}
                    />
                    {inputType !== INPUT_TYPE.NUMBER_WITH_HME && HelpIcon}
                    {inputType !== INPUT_TYPE.NUMBER_WITH_HME && ErrorIcon}
                    {inputType === INPUT_TYPE.NUMBER_WITH_HME && <div className="mana-container">
                        <IconMana />
                        <span className="mana-text">HME</span>
                    </div>}
                </InputInner>
            </div>
            <Tooltip
                placement={placement}
                helpMessage={helpMessage}
                errorMessage={errorMessage}
                hasError={hasError}
                hasHelper={hasHelper}
            />
        </Manager>
    )
}

const NumberInputWithOperator = (props) => {
    const { id, inputClassName, label, inputValue, setInputValue, hover, focus,
        setTouched, onHover, onFocus, onBlur, onChange, hasHelper, helpMessage, placement,
        hasError, errorMessage, disabled
    } = props

    const handleClick = (value) => {
        setInputValue(prev => {
            const currentValue = +prev + +value
            onChange(currentValue)
            return currentValue
        })
        setTouched(true)
    }

    return (
        <div className={`input-number-outer input-number-with-operator-outer  ${disabled ? 'disabled' : ''}`}>
            <button
                className="input-btn prefix"
                onClick={() => handleClick(-1)}
                disabled={disabled}
            >
                <Remove />
            </button>
            <NumberInput
                focus={focus}
                id={id}
                inputClassName={inputClassName}
                inputValue={inputValue}
                label={label}
                placeholder={null}
                hover={hover}
                onHover={onHover}
                onFocus={onFocus}
                onBlur={onBlur}
                disabled={disabled}
                placement={placement}
                helpMessage={helpMessage}
                errorMessage={errorMessage}
                hasError={hasError}
                hasHelper={hasHelper}
            />
            <button
                className="input-btn suffix"
                onClick={() => handleClick(1)}
                disabled={disabled}
            >
                <Add />
            </button>
        </div >
    )
}

const TextArea = (props) => {
    const { id, Ref, inputClassName, label, placeholder, inputValue, setInputValue,
        hover, focus, onChange, onHover, handleFocus, onBlur, onSubmit,
        hasHelper, helpMessage, placement, hasError, errorMessage, disabled } = props

    const handleChange = (e) => {
        const value = e.target.value
        setInputValue(value)
        onChange(e)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onSubmit && onSubmit()
        }
    }

    return (
        <Manager>
            <div className={`input-outer input-textarea-outer ${disabled ? 'disabled' : ''}`}>
                {label && <span className={"input-label"}>{label}</span>}
                <InputInner
                    onHover={onHover}
                    hover={hover}
                    focus={focus}
                >
                    <textarea
                        {...id ? { id: id } : {}}
                        ref={Ref}
                        className={inputClassName}
                        type={INPUT_TYPE.DEFAULT}
                        placeholder={placeholder}
                        value={inputValue}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={onBlur}
                        onKeyDown={handleKeyDown}
                        disabled={disabled}
                    />
                </InputInner>
                <Tooltip
                    placement={placement}
                    helpMessage={helpMessage}
                    errorMessage={errorMessage}
                    hasError={hasError}
                    hasHelper={hasHelper}
                />
            </div >
        </Manager>
    )
}

const ResizableTextArea = (props) => {
    const { id, inputClassName, label, placeholder, defaultValue,
        hover, focus, onChange, onHover, handleFocus, onBlur,
        hasHelper, helpMessage, placement, hasError, errorMessage, disabled } = props
    const Ref = useRef(null)

    useEffect(() => {
        Ref.current.textContent = defaultValue
    }, [Ref, defaultValue])

    const handleChange = (e) => {
        onChange(e.target.textContent)
    }

    return (
        <Manager>
            <div className={`input-outer input-resizable-textarea-outer`} >
                {label && <span className={"input-label"}>{label}</span>}
                <InputInner
                    onHover={onHover}
                    hover={hover}
                    focus={focus}
                >
                    {/* textArea cannot control Resize, so use div instead */}
                    <div
                        {...id ? { id: id } : {}}
                        ref={Ref}
                        className={inputClassName}
                        contentEditable={!disabled}
                        placeholder={placeholder}
                        onInput={handleChange}
                        onFocus={handleFocus}
                        onBlur={onBlur}
                    />
                </InputInner>
                <Tooltip
                    placement={placement}
                    helpMessage={helpMessage}
                    errorMessage={errorMessage}
                    hasError={hasError}
                    hasHelper={hasHelper}
                />
            </div >
        </Manager>
    )
}

Input.defaultProps = {
    className: '',
    type: INPUT_TYPE.DEFAULT,
    label: '',
    placeholder: '',
    defaultValue: '',
    onChange: () => { },
    helpMessage: null,
    errorMessage: null,
    disabled: false,
    dropdownProps: {}
}