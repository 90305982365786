import React from 'react';
import NaviLinks from '../../components/layout/topbarNaviLinks';
import TopbarInfo from '../../components/layout/topbarInfo';
import TopbarLogo from '../../components/layout/topbarLogo';

export default function Topbar(props) {
    const { userService, wallets, currentPathname, currentUser, currentWallet } = props

    return (
        <nav className="topbar">
            <div className="topbar-outer">
                <div className="topbar-content">
                    <TopbarLogo />
                    <NaviLinks
                        currentUser={currentUser}
                        currentWallet={currentWallet}
                        currentPathname={currentPathname}
                    />
                    <TopbarInfo
                        currentUser={currentUser}
                        currentWallet={currentWallet}
                        currentPathname={currentPathname}
                        wallets={wallets}
                        userService={userService}
                    />
                </div>
            </div>
        </nav>
    )
}
