import React from 'react';
import Input from '../kit/input';
import { INPUT_ID } from '../../utils/type';

export default function FormInput(props) {

    const { Ref, inputType, label, placeholder, defaultValue, id, errorMessage, onChange, onFocus, onHover, onBlur, onSubmit, hasError } = props
    return (
        <Input
            Ref={Ref}
            inputType={inputType}
            label={label}
            placeholder={placeholder}
            defaultValue={defaultValue}
            onChange={e => onChange(e.target.value)}
            onFocus={onFocus && errorMessage ? () => onFocus(id) : null}
            onMouseEnter={onHover && errorMessage ? () => onHover(id) : null}
            onMouseLeave={onHover && errorMessage ? () => onHover(INPUT_ID.NONE) : null}
            onBlur={onBlur}
            onSubmit={onSubmit}
            errorMessage={errorMessage}
            hasError={hasError}
        />
    )
}