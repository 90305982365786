import Config from "../config";
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import { ENVIRONMENT, DURATION_UNIT_ITEMS, LOCALE_TYPE } from "./type";
import { formatBalance } from "@polkadot/util";
import * as math from 'mathjs'


momentDurationFormatSetup(moment);

export const isObjectInArrayByKey = (array, key, value) => {
    if (!array || array.length === 0 || !key) {
        return false
    }
    return array.filter(obj => obj[key] === value).length > 0
}

export const getObjectIndexByKey = (array, key, value) => {
    if (!array || array.length === 0 || !key) {
        return false
    }
    return array.findIndex(obj => obj[key] === value)
}

export const hasProperty = (obj) => {
    return (
        typeof obj === typeof {} &&
        obj instanceof Object &&
        !Array.isArray(obj) &&
        Object.keys(obj).length > 0
    )
}

export const isJSON = str => {
    try {
        JSON.parse(str)
        return true
    } catch (e) {
        return false
    }
}

export const toCurrency = num => {
    // TODO: There should be a better way to do this
    const bn = math.bignumber(num)
    return bn.times(math.bignumber(10).pow(18)).trunc().toString()
}

export const formatCurrencyNumber = num => {

    // TODO: #hack un-hardcode this
    return formatBalance(num && num.toString(), { decimals: 18, forceUnit: '-', withSi: false, withUnit: false })
}

export const copyText = id => {
    const copiedRange = document.createRange();
    copiedRange.selectNode(document.getElementById(id));
    const selected = window.getSelection();
    selected.removeAllRanges();
    selected.addRange(copiedRange);
    document.execCommand("copy");
    selected.removeAllRanges();
}

export const firstLowerCase = str => {
    return str.substr(0, 1).toLowerCase() + str.substr(1)
}

export const removeSpace = string => {
    return string.replace(/\s+/g, '')
}

export const shortAddr = address => {
    return `${address.slice(0, 8)}...${address.slice(-8)}`
}

export const tailAddr = (address, length = -5) => {
    return `...${address.slice(length)}`
}

export const arrayBufferToBase64 = buffer => {
    let binary = ''
    let bytes = [].slice.call(new Uint8Array(buffer))
    bytes.forEach((b) => binary += String.fromCharCode(b))

    return window.btoa(binary)
}

export const freezeDeep = object => {
    Object.keys(object).forEach(
        key => typeof object[key] === 'object' && freezeDeep(object[key])
    )
    return Object.freeze(object)
}

export const handleError = error => {
    if (Config.ENVIRONMENT === ENVIRONMENT.DEVELOPMENT) {
        const message = error.name ? `${error.name}: ${error.message}` : error.message
        console.log(error, message)
    } else {
        console.log('oops! something went wrong')
    }
}

export const parseTimeDuration = (duration, unit) => {
    switch (unit) {
        //Days
        case DURATION_UNIT_ITEMS[0]:
            return duration * 24 * 60 * 60 * 1000
        //Hours
        case DURATION_UNIT_ITEMS[1]:
            return duration * 60 * 60 * 1000
        //Minutes
        case DURATION_UNIT_ITEMS[2]:
            return duration * 60 * 1000
        default:
            break
    }
}

const getTimeFormat = () => {
    let timeForAuctBasic, timeForAuctDetail
    const locale = moment.locale()

    switch (locale) {
        case LOCALE_TYPE.EN_US:
            timeForAuctBasic = "y[y] d[d] h[h] m[m]"
            timeForAuctDetail = "y[y] d[d] h[h] m[m] s[s]"
            break
        case LOCALE_TYPE.ZH_TW:
            timeForAuctBasic = "y[年] d[天] h[小時] m[分鐘]"
            timeForAuctDetail = "y[年] d[天] h[小時] m[分鐘] s[秒]"
            break
        default:
            break
    }
    return { timeForAuctBasic, timeForAuctDetail }
}

const getTimeLeft = (end, format, option) => {
    const time = moment(end).diff(moment(), "milliseconds", true)

    if (time <= 0) {
        return ""
    }

    const timeLeft = moment.duration(time).format(format, option)
    return timeLeft
}

export const getBasicTimeLeft = (end) => {
    const format = getTimeFormat().timeForAuctBasic
    const option = {
        largest: 2,
        trim: "both",
        trunc: true,
        minValue: 1
    }
    return getTimeLeft(end, format, option)
}

export const getDetailTimeLeft = (end) => {
    const format = getTimeFormat().timeForAuctDetail
    const option = {
        largest: 2,
        trim: "both"
    }
    return getTimeLeft(end, format, option)
}

export const getDurationTime = (start, end) => {
    const time = moment(end).diff(moment(start), "milliseconds", true)

    const format = getTimeFormat().timeForAuctDetail
    const duration = moment.duration(time).format(format, {
        trim: "both"
    })

    return duration
}

export const isTokenExpired = token => {
    if (!token) {
        return true
    }
    const jwt = decodeToken(token)
    return (Date.now() / 1000 > jwt.exp)
}

export const decodeToken = token => {
    const uri = token.split('.')[1]
    const b64 = decodeURIComponent(
        atob(uri)
            .split('')
            .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
            .join('')
    )
    const jwt = JSON.parse(b64)
    return jwt
}

export const promiseTimeout = (ms, promise) => {
    // Create a promise that rejects in <ms> milliseconds
    let timeout = new Promise((resolve, reject) => {
        let id = setTimeout(() => {
            clearTimeout(id);
            reject('Timed out in ' + ms + 'ms.')
        }, ms)
    })

    // Returns a race between our timeout and the passed in promise
    return Promise.race([
        promise,
        timeout
    ])
}

/**
 * 
 * @param {String} b64Res base64 encoded response object, The format of srcString: B64
 * @return {String} The format of returnString: JSON
 */
export const handleB64Resp = b64Res => {
    if (!b64Res) {
        var err = Error("data not found")
        //#TODO: Need to be fixed
        // err.code = ErrorCode.DATA_NOT_FOUND
        throw err
    }

    const resp = JSON.parse(Buffer.from(b64Res, 'base64'))
    if (!resp) {
        var respErr = Error("data not found")
        //#TODO: Need to be fixed
        // respErr.code = ErrorCode.DATA_NOT_FOUND
        throw respErr
    }

    return resp
}

export const clone = target => {
    return JSON.parse(JSON.stringify(target))
}
