import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigation } from 'react-navi';
import { AccountBalanceWallet } from '@material-ui/icons';
import Button, { BTN_VARIANT } from '../kit/button';
import { PATH, COLOR } from '../../utils/type';

export default function InfoButton(props) {
    const { currentUser, currentWallet, setIsSignInOpen } = props
    const navigation = useNavigation()
    const { t } = useTranslation()

    if (currentWallet) {
        return null
    }

    if (!currentUser) {
        return <div className="info-btn-container">
            <Button
                className="topbar-signin-btn"
                variant={BTN_VARIANT.OUTLINED}
                onClick={() => setIsSignInOpen(true)}
            >
                {t("Login")}
            </Button>
            <Button
                className="topbar-signup-btn"
                variant={BTN_VARIANT.CONTAINED}
                color={COLOR.SECONDARY}
                onClick={() => navigation.navigate(PATH.SIGN_UP)}
            >
                {t("Sign up")}
            </Button>
        </div>
    }

    return <div className="info-btn-container">
        <Button
            className="add-new-wallet-btn"
            variant={BTN_VARIANT.CONTAINED}
            color={COLOR.EVA}
            rounded={true}
            onClick={() => navigation.navigate(PATH.ADD_NEW_WALLET)}
        >
            <AccountBalanceWallet className="icon" />
            {t("Add new wallet")}
        </Button>
    </div>
}