import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-navi';
import { IMAGE_PATH } from '../../utils/type';

export default function TopbarLogo(props) {
    const { t } = useTranslation()

    return <div className="topbar-logo-container">
        <Link href="/" className="armor-logo-link">
            <img className="armor-logo" src={IMAGE_PATH.ARMOR_COLOR_ICON} alt={t("icon logo")} />
        </Link>
        <Link href="/" className="auctions-logo-outer">
            <img className="auctions-logo" src="/images/auctions-logo.svg" alt={t("icon logo")} />
        </Link>
    </div>
}