import config from '../config'
import { AUCTIONS_LENGTH, BIDDING_HISTORY_LENGTH, SORT_CONDITIONS } from '../utils/type'

// const responseToAuctions = (resp) => {
//     return resp.auctions
//         .map(auctions => auctions.value)
//         .map(auction => {
//             auction.submit_time = new Date(auction.submit_time)
//             auction.bidding_end_time = new Date(auction.bidding_end_time)
//             return auction
//         })
// }

const transfersortCondition = (sortCondition) => {
    //Default sort codition for Newest
    let colIndex = 2;
    let desc = true;

    switch (sortCondition) {
        case SORT_CONDITIONS[0]:    //Newest
            colIndex = 2;
            desc = true
            break
        case SORT_CONDITIONS[1]:    //Oldest
            colIndex = 2;
            desc = false
            break
        case SORT_CONDITIONS[2]:    //Lowest Bidding Price
            colIndex = 1
            desc = false
            break
        case SORT_CONDITIONS[3]:    //Highest Bidding Price
            colIndex = 1
            desc = true
            break
        // TODO: Uncomment the following two conditions when apis are ready
        // case SORT_CONDITIONS[4]:    //Lowest Buy now Price
        //     throw Error("Not supported yet")

        // case SORT_CONDITIONS[5]:    //Highest Buy now Price
        //     throw Error("Not supported yet")

        default:
            throw Error("Not supported sort condition: " + sortCondition)
    }

    return { colIndex, desc }
}


export const auctionService = {

    setup(api) {
        this.api = api
    },

    async getAuctions(sortCondition, length = AUCTIONS_LENGTH, page = 1) {
        const sortParams = transfersortCondition(sortCondition)

        const resp = await fetch(`${config.SCANNER_URL}v1/auctions/${sortParams.colIndex}/${sortParams.desc}/${length}/${page}`, {
            method: 'GET',
            mode: 'cors',
        })
        const result = await resp.json()

        return {
            data: result.data,
            total: result.total
        }

    },

    async getAuctionById(id) {
        const resp = await fetch(`${config.SCANNER_URL}v1/auctions/${id}`, {
            method: 'GET',
            mode: 'cors',
        })
        const auction = await resp.json()
        return auction[0]
    },

    async getAuctionsBySellerAddress(sellerAddr, sortCondition, length = AUCTIONS_LENGTH, page = 1) {
        const sortParams = transfersortCondition(sortCondition)

        const resp = await fetch(`${config.SCANNER_URL}v1/user-auctions/${sellerAddr}/${sortParams.colIndex}/${sortParams.desc}/${length}/${page}`, {
            method: 'GET',
            mode: 'cors',
        })
        const result = await resp.json()

        return {
            data: result.data,
            total: result.total
        }

    },

    async getAuctionsByBidderAddress(bidderAddr, sortCondition, length = AUCTIONS_LENGTH, page = 1) {
        const sortParams = transfersortCondition(sortCondition)

        const resp = await fetch(`${config.SCANNER_URL}v1/auctions-bidded/${bidderAddr}/${sortParams.colIndex}/${sortParams.desc}/${length}/${page}`, {
            method: 'GET',
            mode: 'cors',
        })
        const result = await resp.json()

        return {
            data: result.data,
            total: result.total
        }

    },

    async getBidHistory(id, length = BIDDING_HISTORY_LENGTH, page = 1) {
        const resp = await fetch(`${config.SCANNER_URL}v1/bid-history/${id}/${length}/${page}`, {
            method: 'GET',
            mode: 'cors',
        })
        const result = await resp.json()

        return {
            data: result.data,
            total: result.total,
        }
    }
}