export const ARMOR_CHALLENGE = 'aetheras wins'
export const SITE_KEY = "6Lc20PMUAAAAAPKRiRbXerBjc3hcCe2KDQ0ATjQp"
export const DROPDOWN_MINIMUN_WIDTH = '148px'
export const ALL_FORGE_ITEM = 'All Forges'
export const ACCOUNT_PASSWORD_PLACEHOLDER = "Please enter a password"

export const DEFAULT_CURRENT_PAGE = 1
export const DEFAULT_STATS_INDEX = 0
export const AUCTIONS_LENGTH = 12
export const MY_ASSETS_LENGTH = 12
export const BIDDING_HISTORY_LENGTH = 10
export const WALLET_PHRASE_LENGTH = 24
export const USERNAME_MIN_LENGTH = 4
export const USERNAME_MAX_LENGTH = 30
export const GAS_MIN_FEE = 400000
// TODO: unhardcode this
export const CREATE_FORGE_FEE = 100000000000000000000n
export const AUCTION_COUNTDOWN_MINUTES = 60
export const MOBILE_HIDDEN_POSITION = -4
export const MOBILE_SHOW_POSITION = 48
export const WRAPPER_SHOW_POSITION = 52
export const MIN_ACCOUNT_PASSWORD_LENGTH = 8
export const MAX_ACCOUNT_PASSWORD_LENGTH = 64
export const MIN_WALLET_PASSWORD_LENGTH = 8
export const MAX_WALLET_PASSWORD_LENGTH = 32
export const MIN_NUMBER_OF_ELEMENTS = 1
export const WALLET_PASSWORD_SPECIAL_CHARS = "!#$%&?"

export const MINIMUM_AUCTION_PRICE = 1000000000000000000n
export const AUCTION_DURATION_LIMIT = 1
export const MAXIMUM_DECAY_RATE = 100

export const ASSET_STANDARD = ['ARC v1.0', 'Custom']
export const DURATION_UNIT_ITEMS = ['Days', 'Hours', 'Minutes']
//TODO: Uncomment the following code when apis support sorting by Lowest/ Highest Buy now Price
//export const SORT_CONDITIONS = ['Newest', 'Oldest', 'Lowest Bidding Price', 'Highest Bidding Price', 'Lowest Buy now Price', 'Highest Buy now Price']
export const SORT_CONDITIONS = ['Newest', 'Oldest', 'Lowest Bidding Price', 'Highest Bidding Price']
export const ASSETS_SORT_CONDITIONS = ['Newest', 'Oldest']

export const SUBSTRATE_EVENT_EXTRINSIC_SUCCESS = '0x0000'
export const SUBSTRATE_EVENT_EXTRINSIC_FAILED = '0x0001'

export const AGORA_SCAN_AUCTION_STATUS = Object.freeze({
    ACTIVE: 'Active',
    EXPIRED: 'Expired',
    CANCELLED: 'Cancelled',
    WON: 'Won',
})

export const POLKADOT_ERROR_CODE = Object.freeze({
    UNABLE_PAY_FEES: '1010:',
})

export const ARMOR_ERROR = Object.freeze({
    DUPLICATE_EMAIL: `"users_unique_email_idx"`,
})

export const LOCALE_TYPE = Object.freeze({
    EN_US: 'en',
    ZH_TW: 'zh-tw'
})

export const CREATE_ASSET_KIND_WAYS = Object.freeze({
    JSON: 'Import JSON',
    IPFS: 'Import IPFS Hash'
})

export const AUCTION_KIND = Object.freeze({
    BASIC: 'Basic',
    WITH_BUY_NOW: 'WithBuyNow',
    BUY_ONLY: 'BuyOnly',
    REVERSE: 'Reverse'
})

export const DISPLAYED_AUCTION_KIND = Object.freeze({
    BASIC: 'Basic',
    WITH_BUY_NOW: 'With Buy Now',
    BUY_ONLY: 'Buy Only',
    REVERSE: 'Reverse'
})

export const AUCTION_KIND_INDEX = Object.freeze({
    BASIC: 0,
    WITH_BUY_NOW: 1,
    BUY_ONLY: 2,
    REVERSE: 3
})

export const EMPTY_STAT = Object.freeze({
    headerPlaceholder: "Stat Type",
    sectionPlaceholder: "Stat Value",
    headerValue: "",
    sectionValue: ""
})

export const ENVIRONMENT = Object.freeze({
    DEVELOPMENT: 'development',
    PRODUCTION: 'production',
    TEST: 'test'
})

export const DEFAULT_DENOM = Object.freeze({
    VALUE: 'HME',
    DISPLAY: 'HME'
})

export const ARMOR_METHOD = Object.freeze({
    USER_SIGN_UP: 'users_register',
    USER_SIGN_IN: 'users_getToken',
    USER_NEW_REFRESH_TOKEN: 'users_newRefreshToken',
    USER_NEW_TOKEN: 'users_newToken',
    USER_GETBYID: 'users_getById',
    USER_UPDATE_PASSWORD: 'users_updatePassword',
    USER_ADD_AGORA_ADDRESS: 'users_addAgoraAddress',
    USER_REMOVE_AGORA_ADDRESS: 'users＿removeAgoraAddress',
})

export const LINK = Object.freeze({
    githubURL: 'https://github.com/aetheras-io',
    twitterURL: 'https://twitter.com/aetheras_io',
    discordURL: 'https://discord.gg/R9TCtyh',
    agenceExplorerURL: 'https://explorer.takecopter.agence.network',
    agenceFaucetURL: 'https://explorer.takecopter.agence.network/faucet',
    contactMail: 'mailto:business@aetheras.io',
})

export const UNITY_GAMES = Object.freeze({
    MUGEN_TD: {
        name: 'Tower Defense',
        link: 'https://gametd.aetheras.io/'
    },
    RPG: {
        name: 'RPG',
        link: 'https://gamerpg.aetheras.io/'
    }
})

export const PATH = Object.freeze({
    HOME: '/',
    LANDING: '/started',
    AUCTIONS: '/auctions',
    FEATURED_AUCTIONS: '/auctions/featured',
    LISTED_AUCTIONS: '/auctions/listed',
    FEATURED_AUCTIONS_SEARCH: '/auctions/featured/search',
    LISTED_AUCTIONS_SEARCH: '/auctions/listed/search',
    AUCTION_DETAIL: '/auctions/:id',
    MY_AUCTIONS: '/my_auctions',
    MY_BIDDING_AUCTIONS: '/my_auctions/bidding',
    MY_SELLING_AUCTIONS: '/my_auctions/selling',
    MY_BIDDING_AUCTIONS_SEARCH: '/my_auctions/bidding/search',
    MY_SELLING_AUCTIONS_SEARCH: '/my_auctions/selling/search',
    SIGN_IN: '/sign_in',
    SIGN_UP: '/sign_up',
    CHECK_EMAIL: '/check_email',
    CHANGE_EMAIL: '/change_email',
    FORGOT_PASSWORD: '/forgot_password',
    RESET_PASSWORD: '/reset_password',
    VERIFY_USER: '/verify_user',
    MY_ASSETS: '/my_assets',
    MY_ASSETS_OWNED: '/my_assets/owned',
    MY_ASSETS_HISTORY: '/my_assets/history',
    MY_ASSETS_OWNED_SEARCH: '/my_assets/owned/search',
    MY_ASSETS_HISTORY_SEARCH: '/my_assets/history/search',
    ASSET_DETAIL: '/assets/:id',
    TX_FEE: '/tx_fee',
    SUMMARY: '/summary',
    PAYMENT: '/payment',
    PAID: '/paid',
    CREATE_AUCTION: '/create_auction',
    CREATE_ASSETS: '/create_assets',
    SELECT_ASSET: '/select_asset',
    SELECT_ASSET_SEARCH: '/select_asset/search',
    MY_FORGE: '/my_forge',
    MY_FORGE_SEARCH: '/my_forge/search',
    CREATE_FORGE: '/create_forge',
    CREATE_KIND: '/create_kind',
    ALREADY_SOLD: '/already_sold',
    ALREADY_SOLD_SEARCH: '/already_sold/search',
    MINT_ASSETS: '/mint_assets',
    MINT_ASSETS_SEARCH: '/mint_assets/search',
    MINT_ASSETS_HASH: '/mint_assets_hash',
    MINT_ASSETS_JSON: '/mint_assets_json',
    PROFILE: '/profile',
    ACCOUNT_SETTINGS: '/account_settings',
    WALLET_SETTINGS: '/wallet_settings',
    ADD_NEW_WALLET: '/add_new_wallet',
    IMPORT_WALLET: '/import_wallet',
    RESTORE_WALLET: '/restore_wallet',
    CREATE_PASSWORD: '/create_password',
    BACKUP_PHRASE: '/backup_phrase',
    CONFIRM_PHRASE: '/confirm_phrase',
    WALLET_CREATED: '/wallet_created',
    TEST_TRANSACTION: '/test_transaction',
    TOS_NOTICE: '/tos',
    PRIVACY_NOTICE: '/privacy',
    NOT_FOUND: '/404',
    KIT: '/kit'
})

export const PATH_TEMPLATE = Object.freeze({
    AUCTION_DETAIL: (id) => `/auctions/${id}`,
    ASSET_DETAIL: (id) => `/assets/${id}`,
    PAGE: (path, num) => `${path}?p=${num}`,
    FORGE_AND_PAGE: (path, forgeId, pageNo) => `${path}?f=${forgeId}&p=${pageNo}`,
    SEARCH: (path, query) => `${path}/search?q=${query}`,
    FORGE_AND_SEARCH: (path, forgeId, query) => `${path}/search?f=${forgeId}&q=${query}`,
    FORGE: (path, forgeId) => `${path}?f=${forgeId}`,
})

export const VIDEO_PATH = Object.freeze({
    BANNER_ARMOR: '/video/banner-armor.webm',
})

export const IMAGE_PATH = Object.freeze({
    ASSET_POSTER: '/images/armor-poster.png',
    ASSET_PLACEHOLDER: '/images/noPhoto.svg',
    ARMOR_ERROR: "/images/armor-error.png",
    ARMOR_COLOR_ICON: "/images/icon-armor-colored.svg",
    ARMOR_ICON: "/images/icon-armor.svg",
    ARMOR_EMAIL: "/images/armor-email.png",
    AGORA_WALLET_ONE: '/images/armor-with-wallet-1.png',
    ARMOR_WALLET_TWO: "/images/armor-with-wallet-2.png",
    AGORA_CREATE_ONE: '/images/armor-create-1.png',
    AGORA_CREATE_TWO: '/images/armor-create-2.png',
    AUCTIONS_LOGO: '/images/auctions-logo.svg',
    ICON_ARMOR: '/images/icon-armor.svg',
    EXPLORER_LOGO: '/images/explorer-logo.svg',
    GAME_FPS: '/images/gameVisual_fps.jpg',
    GAME_RPG: '/images/gameVisual_rpg.jpg',
    GAME_TD: '/images/gameVisual_td.jpg',
    GAME_HORROR: '/images/gameVisual_horror.jpg',
    GAME_ACT: '/images/gameVisual_girl.jpg',
    GUIDE_ONE: '/images/gettingStarted-1.jpg',
    GUIDE_TWO: '/images/gettingStarted-2.jpg',
    GUIDE_THREE: '/images/gettingStarted-3.jpg',
    GUIDE_FOUR: '/images/gettingStarted-4.jpg',
    GUIDE_FIVE: '/images/gettingStarted-5-2.png',
})

export const DISPLAY_MSG = Object.freeze({
    TIME_UP: 'Time up',
})

export const SEARCH_ITEM_TYPE = Object.freeze({
    AUCTION: 'auction',
    ASSET: 'asset',
})

export const AUCTION_LIST_TYPE = Object.freeze({
    FEATURED: 'FEATURED',
    LISTED: 'LISTED',
    BIDDING: 'BIDDING',
    SELLING: 'SELLING',
})

export const ASSET_TYPE = Object.freeze({
    BASIC: 'BASIC',
    ROW: 'ROW',
})

export const CHECK_EMAIL_TYPE = Object.freeze({
    SIGN_IN: 'SIGN_IN',
    SIGN_UP: 'SIGN_UP',
    NO_RESEND: 'NO_RESEND',
})

export const DATE_FORMAT = Object.freeze({
    LONG_DATE: 'LL',
    DATE: 'll',
    SHORT_DATE: 'l',
    LONG_TIME: 'LTS',
    TIME: 'LT',
    LONG_DATE_TIME: 'MMMM Do YYYY, h:mm:ss a',
    DATE_TIME: 'MMM Do YY, h: mm: ss a'
})

export const ORDER_BY = Object.freeze({
    ASC: 'ASC',
    DESC: 'DESC'
})

export const ACTION_TYPE = Object.freeze({
    OPEN_FORGE: "Open a Forge",
    CREATE_ASSET_KIND: "Create Asset Kind",
    CREATE_ASSET: "Create Asset",
    NEW_AUCTION: "New Auction",
    PLACE_BID: "Place Bid",
    BUY_IT_NOW: "Buy it Now",
})

export const ADD_WALLET_TYPE = Object.freeze({
    IMPORT: "Import",
    RESTORE: "Restore",
    PAYMENT: "Payment",
})

export const AUTH_STEP = Object.freeze({
    REGISTER: 'REGISTER',
    LOGIN: 'LOGIN',
    FORGOT_PASSWORD: 'FORGOT_PASSWORD'
})

export const AUCTION_STATUS = Object.freeze({
    SETUP_PERIOD: 'SetupPeriod',
    BIDDING_PERIOD: 'BiddingPeriod',
    WON: 'Won',
    NOWINNER: 'NoWinner'
})

export const AUCTION_STATUS_DISPLAY = Object.freeze({
    SetupPeriod: {
        class: 'setup',
        btnText: 'SETUP'
    },
    BiddingPeriod: {
        class: 'bidding',
        btnText: 'BUY'
    },
    Won: {
        class: 'won',
        btnText: 'SOLD'
    },
    NoWinner: {
        class: 'no-winner',
        btnText: 'OUTBID'
    }
})

export const ARROW_TYPE = Object.freeze({
    NEXT: 'next',
    PREV: 'prev',
})

export const COLOR = Object.freeze({
    DEFAULT: 'white',
    DARK: 'dark',
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    DANGER: 'danger',
    EVA: 'eva',
})

export const KEY_CODE = Object.freeze({
    TAB: 9,
    ENTER: 13
})

export const ERROR_CODE = Object.freeze({
    CURRENT_USER_NOTFOUND: 1000
})

export const ERROR_MSG = Object.freeze({
    ABOVE_AUCTION_PRICE: "Decay Price should be less than Price",
    NULL_USERNAME: "Please enter your new username",
    NULL_NEW_EMAIL: "Please enter your new email address",
    NULL_EMAIL: "Please enter your email address",
    NULL_FEE: 'Please enter the fee',
    NULL_GAS: 'Please enter the Gas',
    REGEX_USERNAME: "Your username must fit the format",
    REGEX_ACCOUNT_PASSWORD: "Your password must fit the format",
    REGEX_WALLET_PASSWORD: "Only alphabet, numberic, and some special characters are allowed",
    REGEX_EMAIL: 'Please enter a valid email',
    EXISTED_USERNAME: "There is already an account for this username",
    EXISTED_EMAIL: "This email is already registered. Please try another one.",
    WRONG_PASSWORD: "Incorrect password",
    PASSWORD_NOT_MATCH: "Passwords don’t match. Please try again.",
    PHRASE_NOT_MATCH: "Incorrect backup phrase. Please try again.",
    INCORRECT: "Incorrect email or password",
    REQUIRED: "Required",
    INVALID_DURATION: "Invalid duration",
    INVALID_PRICE: "Invalid price",
    INVALID_RATE: "Invalid rate",
    UNABLE_PAY_FEES: "Unable to pay fees. Account balance may be too low",
    UNABLE_PAY_FEES_WARN: "This transaction may fail. Account balance is lower than estimated fee: some price",
    BELOW_AUCTION_PRICE: "Buy Now Price should be greater than Price",
    BELOW_MINIMUM_AUCTION_PRICE: "At least some price for auction price",
    BELOW_MIN_CHAR_LENGTH: "At least some characters",
    ASSETKIND_ID_ALREADY_USED: "Asset Kind Id: some id has already been used",
    UNSUPPORT_ASSET_STATS_FORMAT: "Warning: Stats data with unsupported format",
    BETWEEN_CHAR_LENGTH: "Between some characters",
    NO_SEARCHING_ASSET: "No searching asset",
    NO_SEARCHING_ASSET_KIND: "No searching asset kind",
    NO_FORGE_ASSET: "No forge asset",
    NO_SOLD_FORGE_ASSET: "No sold forge asset",
    NO_MATCHING_DATA: "No matching data",
    NO_SEARCHING_MY_BID: "No searching my bid auction",
    NO_SEARCHING_MY_LIST: "No searching my list auction",
    NONE: ""
})

export const INPUT_ID = Object.freeze({
    NONE: 0,
    USERNAME: 1,
    DISPLAY_NAME: 2,
    EMAIL: 3,
    PASSWORD: 4,
    CONFIRM_PASSWORD: 5,
    NEW_PASSWORD: 6,
    RE_PASSWORD: 7,
    PHRASE: 8,
    ACCOUNT: 9,
    FEE: 10,
    GAS: 11,
    TITLE: 12,
    DURATION: 13,
    PRICE: 14,
    DESCRIPTION: 15,
    BUY_NOW_PRICE: 16,
    ASSET_KIND_ID: 17,
    IPFS_HASH: 18,
    ALT_DATA_SOURCE: 19,
    DECAY_PRICE: 20,
    DECAY_RATE: 21
})

export const INPUT_TYPE = Object.freeze({
    DEFAULT: 'text',
    PASSWORD: 'password',
    NUMBER: 'number',
    NUMBER_WITH_HME: 'number-with-mana',
    NUMBER_WITH_OPERATION: 'number-with-operation',
    WITH_DROPDOWN: 'with-dropdown',
    TEXTAREA: 'textarea',
    RESIZABLE_TEXTAREA: 'resizable-textarea'
})

export const DROPDOWN_TYPE = Object.freeze({
    DEFAULT: 'default',
    OUTLINED: 'outlined',
    ADD_ITEM: 'addItem',
    OVAL: 'oval',
})

export const SEARCH_TYPE = Object.freeze({
    DEFAULT: 'default',
    NORMAL: 'normal',
    MOBILE: 'mobile',
})

export const FORGE_TYPE = {
    ALL: 'All',
    SOLD: 'Sold',
}

export const FORGE_POSITION = {
    IN_FORGE: 'In Forge',
    SOLD: 'Sold',
}

export const ARRAY_KEY = Object.freeze({
    ID: 'id',
    ADDRESS: 'address',
})

export const REGEX = Object.freeze({
    EMAIL: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    USERNAME: /^[a-zA-Z0-9]{4,30}$/,
    ACCOUNT_PASSWORD: /^(?=[^\s]*[a-z])(?=[^\s]*[A-Z])(?=[^\s]*[0-9])[^\s]{8,64}$/,
    WALLET_PASSWORD: /^[a-zA-Z0-9!#$%&?]{8,32}$/,
    ASSET_ID: /^[0-9]+[xX]{1}[0-9]+[xX]{1}[0-9]+$/,
    AUCTION_ID: /^[0-9]+$/,
    ASSET_KIND_ID: /^[0-9]+$/
})

export const STEP_ID = Object.freeze({
    FIRST: 1,
    SECOND: 2,
    THIRD: 3,
    FOURTH: 4,
    FIFTH: 5
})

export const LOCAL_STORAGE_KEYS = Object.freeze({
    CURRENT_USER: 'currentUser',
    CURRENT_WALLET: 'currentWallet',
    WALLET_MAPPINGS: 'walletMappings',
})

export const ASSET_STATS_STANDARD = Object.freeze({
    rarity: 0,
    require_lv: 0,
    type: 0,
    str: 0,
    con: 0,
    def: 0,
    dex: 0,
    inte: 0,
    cha: 0,
    wis: 0,
    wil: 0,
    per: 0,
    luc: 0
})

export const SIGNIN_TYPE = Object.freeze({
    ROUTES: 'routes',
    MODAL: 'modal'
})

export const WINDOW_WIDTH_TYPE = Object.freeze({
    XL: 1176,
    L: 960,
    M: 720,
    S: 540,
    XS: 0,
})