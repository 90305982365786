import { ENVIRONMENT } from "./utils/type";

const common = {
    IPFS_URL: 'https://ipfs.aetheras.io/ipfs/',
    IPFS_ADD_URL: 'https://api.aetheras.io/addipfs',
    GAS_PRICE: 0,   //Not used in agence-substrate,
}

const dev = {
    ...common,
    ENVIRONMENT: ENVIRONMENT.DEVELOPMENT,
    SERVER_URL: 'http://localhost:3030/',
    SCANNER_URL: 'http://localhost:3032/',   //#TEMP: Need to be discussed later
    NODE_URL: 'ws://127.0.0.1:9944',
}

const prod = {
    ...common,
    ENVIRONMENT: ENVIRONMENT.PRODUCTION,
    SERVER_URL: 'https://armor.cloud.aetheras.io/',
    SCANNER_URL: 'https://scan.takecopter.cloud.agence.network/',
    NODE_URL: 'wss://ws.takecopter.cloud.agence.network',
}

export default process.env.NODE_ENV === ENVIRONMENT.PRODUCTION ? prod : dev;