import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-navi';
import { PATH, LINK } from '../../utils/type';
import NaviIcon from './topbarNaviIcon';
import Games from './topbarGames';
import {
    ExpandMore,
    ExpandLess,
    Storefront,
    Widgets,
    Store,
    ListAlt,
    Timeline,
    Gavel,
    ContactSupport
} from '@material-ui/icons';

export default function NaviLinks(props) {
    const { currentPathname, currentUser, currentWallet } = props
    const [isNaviMenuOpen, setIsNaviMenuOpen] = useState(false)
    const [currentPageName, setCurrentPageName] = useState(null)
    const { t } = useTranslation()
    const naviBtnRef = useRef(null)
    const naviMenuRef = useRef(null)

    useEffect(() => {
        const onMenuClose = (event) => {
            const isMenuClicked = naviMenuRef.current && naviMenuRef.current.contains(event.target)
            const isBtnClicked = naviBtnRef.current && naviBtnRef.current.contains(event.target)
            if (!isMenuClicked && !isBtnClicked) {
                setIsNaviMenuOpen(false)
            }
        }

        document.addEventListener('click', onMenuClose, { capture: true })
        return () => {
            document.removeEventListener('click', onMenuClose, { capture: true })
        }
    }, [])

    const onClose = () => {
        setIsNaviMenuOpen(false)
    }

    useEffect(() => {
        switch (currentPathname) {
            case PATH.FEATURED_AUCTIONS:
            case PATH.LISTED_AUCTIONS:
                setCurrentPageName(t("Discover"))
                break
            case PATH.MY_ASSETS_OWNED:
            case PATH.MY_ASSETS_HISTORY:
                setCurrentPageName(t("My Assets"))
                break
            case PATH.MY_FORGE:
            case PATH.ALREADY_SOLD:
            case PATH.CREATE_ASSETS:
            case PATH.CREATE_KIND:
            case PATH.MINT_ASSETS:
                setCurrentPageName(t("My Forge"))
                break
            case PATH.MY_AUCTIONS:
                setCurrentPageName(t("My Auct."))
                break
            case PATH.TEST_TRANSACTION:
                setCurrentPageName(t("How to use"))
                break
            default:
                setCurrentPageName(null)
                break
        }
    }, [currentPathname, t])

    return <Navi currentPageName={currentPageName} naviBtnRef={naviBtnRef} naviMenuRef={naviMenuRef} isNaviMenuOpen={isNaviMenuOpen} setIsNaviMenuOpen={setIsNaviMenuOpen} >
        {currentUser && <>
            {/* TODO: First path needs to change to PATH.FEATURED_AUCTIONS when Featured Auction api is ready */}
            <NaviLink path={[PATH.LISTED_AUCTIONS]} currentPathname={currentPathname} icon={Storefront} text={t("Discover")} onClick={onClose} />
            <NaviLink path={[PATH.MY_ASSETS]} currentPathname={currentPathname} icon={Widgets} text={t("My Assets")} onClick={onClose} />
            {currentWallet && <NaviLink path={[PATH.MY_FORGE, PATH.CREATE_FORGE, PATH.ALREADY_SOLD, PATH.CREATE_ASSETS, PATH.CREATE_KIND, PATH.MINT_ASSETS]} currentPathname={currentPathname} icon={Store} text={t("My Forge")} onClick={onClose} />}
            <NaviLink path={[PATH.MY_AUCTIONS]} currentPathname={currentPathname} icon={ListAlt} text={t("My Auct")} onClick={onClose} />
            <Games currentWallet={currentWallet} />
            {currentWallet && <>
                <div className="divider" />
                <NaviLink className="navi-link link-newAuct" path={[PATH.SELECT_ASSET]} currentPathname={currentPathname} icon={Gavel} text={t("New Auct")} onClick={onClose} />
            </>}
        </>}
        {!currentUser && <>
            {/*TODO: change First path to change to PATH.FEATURED_AUCTIONS*/}
            <NaviLink path={[PATH.LISTED_AUCTIONS]} currentPathname={currentPathname} icon={Storefront} text={t("Discover")} onClick={onClose} />
            <NaviLink path={[`${PATH.LANDING}#home-guide`]} currentPathname={currentPathname} icon={ContactSupport} text={t("How to use")} onClick={onClose} />

            <a href={LINK.agenceExplorerURL} className="navi-link selector-item " target="_blank" rel="noopener noreferrer" onClick={onClose}>
                <Timeline className="icon" />
                <span className="navi-text h6">{t("Explorer")}</span>
            </a>
        </>}
    </Navi>
}

function NaviLink(props) {
    const { className, path, currentPathname, icon: Icon, text, onClick } = props
    let isCurrent = false
    path.forEach(p => {
        const pathSubdir = `/${p.split("/")[1]}`
        if (currentPathname.startsWith(pathSubdir)) {
            isCurrent = true
        }
    })

    return <Link href={path[0]} className={`navi-link selector-item ${isCurrent ? 'current' : ''} ${className}`} onClick={onClick}>
        <div className="navi-icon">
            <Icon className='icon' />
        </div>
        <span className="h6 navi-text">{text}</span>
    </Link>
}

NaviLink.defaultProps = {
    className: "",
}

function Navi(props) {
    const { children, naviBtnRef, currentPageName, naviMenuRef, setIsNaviMenuOpen, isNaviMenuOpen } = props

    return <div className="navi-container">
        <div className="navi-list">
            {children}
        </div>
        <NaviBtn naviBtnRef={naviBtnRef} setIsNaviMenuOpen={setIsNaviMenuOpen} isNaviMenuOpen={isNaviMenuOpen} currentPageName={currentPageName} />
        <NaviMenu naviMenuRef={naviMenuRef} children={children} isNaviMenuOpen={isNaviMenuOpen} />
    </div >
}

function NaviBtn(props) {
    const { setIsNaviMenuOpen, isNaviMenuOpen, naviBtnRef, currentPageName } = props

    const arrowIcon = isNaviMenuOpen ? <ExpandLess className="icon" /> : <ExpandMore className="icon" />

    if (currentPageName) {
        return <button ref={naviBtnRef} className="selector-btn" onClick={() => setIsNaviMenuOpen(prev => !prev)}>
            <span className="btn-text">{currentPageName}</span>
            {arrowIcon}
        </button>
    }

    return <button ref={naviBtnRef} className="selector-btn" onClick={() => setIsNaviMenuOpen(prev => !prev)}>
        <NaviIcon />
    </button>
}

function NaviMenu(props) {
    const { children, isNaviMenuOpen, naviMenuRef } = props

    if (!isNaviMenuOpen) {
        return null
    }

    return <div className="selector-menu-outer mobile-menu-dropdown" ref={naviMenuRef}>
        <div className="selector-menu">
            {children}
        </div>
    </div>
}
