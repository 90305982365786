import React, { useState, useContext, useEffect } from 'react'
import { userService } from '../../api/users'
import { useAccounts } from './account'

const UsersContext = React.createContext({})
const useUsers = () => {
    return useContext(UsersContext)
}

const defaultState = {
    userServiceReady: false,
    currentUser: null,
    currentWallet: null,
    wallets: []
}

const UsersProvider = ({ children }) => {
    const [state, setState] = useState(defaultState)
    const accountState = useAccounts()
    const { accountServiceReady } = accountState

    useEffect(() => {
        if (!accountServiceReady) {
            return
        }

        userService.subscribe((userState) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    ...userState,
                    userServiceReady: true
                }
            })
        })

        userService.getState()

        return () => {
            userService.cleanupState()
        }
    }, [accountServiceReady])

    return (
        <UsersContext.Provider value={{ state, userService }}>
            {children}
        </UsersContext.Provider>
    )
}

export { useUsers, UsersProvider }
