import React from 'react';
import { Link } from 'react-navi';
import { PATH, IMAGE_PATH } from '../utils/type';
import { useTranslation } from 'react-i18next';

export default function NotFound(props) {
    const { t } = useTranslation()

    return (
        <div className="no-result-container">
            <div className="no-result-img-container">
                <img className="no-result-img" src={IMAGE_PATH.ARMOR_ERROR} alt={t("logo")} />
                <span className="no-result-img-text">
                    {t("404.")}
                </span>
            </div>
            <div className="no-result-text-container">
                <Link href={PATH.HOME}>
                    {t("Head back to home")}
                </Link>
            </div>
        </div >
    )
}