import config from '../config'
import * as Util from '@polkadot/util'
import { promiseTimeout } from '../utils/common'
import { MY_ASSETS_LENGTH, ASSETS_SORT_CONDITIONS } from '../utils/type'

// var storageNameKey = Util.crypto.xxhashAsHex("Assets", 128)
// var storageItemKey = Util.crypto.xxhashAsHex("Asset", 128)
// var assetsQueryKey = storageNameKey + storageItemKey.substring(2)

// const assetsQueryKey = '0x682a59d51ab9e48a8c8cc418ff9708d2d34371a193a751eea5883e9553457b2e'

const transfersortCondition = (sortCondition) => {
    //Default sort codition for Newest
    let desc = true;

    switch (sortCondition) {
        case ASSETS_SORT_CONDITIONS[0]:    //Newest
            desc = true
            break
        case ASSETS_SORT_CONDITIONS[1]:    //Oldest
            desc = false
            break
        default:
            throw Error("Not supported sort condition: " + sortCondition)
    }

    return desc
}

const getIpfsData = async (asset, timeout_millisec = 10000) => {
    try {
        const ipfsHandle = promiseTimeout(timeout_millisec, fetch(config.IPFS_URL + asset.ipfs + '/attrs.json', {
            method: 'GET',
            mode: 'cors',
        }))
        const res = await ipfsHandle
        const attrs = await res.json()
        asset.attrs = attrs
    } catch (err) {
        console.log(err)
    }

    asset.image = config.IPFS_URL + asset.ipfs + '/image.png'

    return asset
}

const transformVO = (sourceId, assetTypeId, assetId, assetContent) => {
    const result = assetContent.toJSON()
    result.id = sourceId + 'x' + assetTypeId + 'x' + assetId
    result.forge_id = sourceId
    result.forgeName = sourceId.toString()

    return result
}

const handleAssetRespFromAgenceScan = async (assets) => {
    const completeAssets = await Promise.all(assets.map(async (asset) => {
        const ipfsHash = Util.u8aToString(new Uint8Array(asset.class_data))

        const metadata = Util.u8aToString(new Uint8Array(asset.metadata))
        const assetComplete = await getIpfsData({ ipfs: ipfsHash })

        return {
            ...asset,
            ...assetComplete,
            id: asset.source_id + 'x' + asset.asset_kind_id + 'x' + asset.asset_id,
            //#TODO: Need to store forgeName in the future 
            forgeName: asset.source_id.toString(),
            forge_id: asset.source_id,
            ipfs: ipfsHash,
            metadata: metadata
        }
    }))

    return completeAssets
}

// const assetsFromResponse = async (resp) => {
//     const assets = resp.assets.map(asset => transformVO(asset))

//     return await Promise.all(assets.map(async asset => {
//         asset = await getIpfsData(asset)
//         return asset
//     }))
// }

export const assetService = {
    setup(api) {
        this.api = api
    },

    /***
    * create new assetKind before minting asset
    * 
    * @returns The response of the request
    * @param {Number} forgeId forge ID
    * @param {string} assetKindId assetKind ID
    * @param {string} dataHash IPFS hash for Aetheras IPFS node
    * @param {string} dataURI Optional, External source of data, can be verified by dataHash
    * 
    */
    async createAssetKind(source_id, asset_type_id, data_hash, data_uri) {
        await this.api.tx.nft.createAssetKind(source_id, asset_type_id, data_hash, data_uri)
    },

    /***
    * add asset image and attribute data to IPFS 
    * 
    * @returns IPFS hash
    * @param {JSON} asset JSON data that include asset image and attribute data
    * 
    */
    async addAssetToIPFS(asset) {
        const headers = new Headers({ 'Content-Type': 'application/json' })
        headers.append('Authorization', 'bZcg3YbsenUQa/AqHHX6H44Sj4tF4uM/6JQqQTITemg=')

        let res = await fetch(config.IPFS_ADD_URL, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                image: asset.image.split(',')[1],
                attrs: asset.attrs
            })
        })

        res = await res.text()

        if (!res) { throw Error('Empty Response') }

        if (res.error) {
            let err = Error(res.error.message)
            err.code = res.error.code
            throw err
        }
        return res
    },

    async getAssetKind(sourceId, assetTypeId) {
        const assetKindOption = await this.api.query.nft.classes([sourceId, assetTypeId])
        if (assetKindOption.isNone) {
            return null
        }
        return assetKindOption.unwrap().toJSON()
    },

    async getAsset(sourceId, assetTypeId, assetId) {
        const assetContent = await this.api.query.nft.tokens([sourceId, assetTypeId], assetId)
        const assetKind = await this.getAssetKind(sourceId, assetTypeId)
        const ipfsHash = assetKind.data

        const assetVO = transformVO(sourceId, assetTypeId, assetId, assetContent)
        assetVO.ipfs = Util.hexToString(ipfsHash)
        assetVO.metadata = Util.hexToString(assetVO.metadata)

        const assetComplete = await getIpfsData(assetVO)

        return assetComplete
    },

    async getAssetById(id) {
        const idList = id.toLowerCase().split('x')
        if (idList.length < 3) {
            return {}
        }
        return await this.getAsset(idList[0], idList[1], idList[2])
    },

    async getPaginatedAssetsByURL(url) {
        const resp = await fetch(url, {
            method: 'GET',
            mode: 'cors',
        })

        const result = await resp.json()
        const assets = await handleAssetRespFromAgenceScan(result.data)

        return {
            data: assets,
            total: result.total
        }
    },

    async getAssetKindsByForgeWithoutIPFS(forgeID) {
        const resp = await fetch(`${config.SCANNER_URL}v1/assetkinds/${forgeID}`, {
            method: 'GET',
            mode: 'cors',
        })

        const kinds = await resp.json()
        return kinds
    },

    async getAssetKindsByForge(forgeID) {
        const kinds = await this.getAssetKindsByForgeWithoutIPFS(forgeID)
        const completeKinds = await Promise.all(kinds.map(async (kind) => {
            const ipfsHash = Util.u8aToString(new Uint8Array(kind.data))
            const assetComplete = await getIpfsData({ ipfs: ipfsHash })
            const assetKind = await this.getAssetKind(kind.source_id, kind.asset_kind_id)

            return {
                ...kind,
                ...assetComplete,
                published_counter: assetKind.volume,
            }
        }))

        return completeKinds
    },

    async getAllAssetsByForge(forgeID, sortCondition, length = MY_ASSETS_LENGTH, page = 1) {
        const desc = transfersortCondition(sortCondition)
        const url = `${config.SCANNER_URL}v1/source-assets/${forgeID}/${desc}/${length}/${page}`

        return await this.getPaginatedAssetsByURL(url)
    },

    async getSoldAssetsByForge(forgeID, sortCondition, length = MY_ASSETS_LENGTH, page = 1) {
        const desc = transfersortCondition(sortCondition)
        const url = `${config.SCANNER_URL}v1/source-assets-sold/${forgeID}/${desc}/${length}/${page}`

        return await this.getPaginatedAssetsByURL(url)
    },


    async getAssetsByKind(forgeID, kindID) {
        const resp = await fetch(`${config.SCANNER_URL}v1/assets/${forgeID}/${kindID}`, {
            method: 'GET',
            mode: 'cors',
        })

        const assetsJSON = await resp.json()
        const assets = await handleAssetRespFromAgenceScan(assetsJSON)
        return assets
    },

    async getAssetByAuction(auction) {
        const asset = await this.getAsset(auction.source_id, auction.asset_kind_id, auction.asset_id)
        return asset
    },

    async getPaginatedAssetsByAddress(address, sortCondition, length = MY_ASSETS_LENGTH, page = 1) {
        const desc = transfersortCondition(sortCondition)
        const url = `${config.SCANNER_URL}v1/account-assets/${address}/${desc}/${length}/${page}`

        return await this.getPaginatedAssetsByURL(url)
    },

    async getAssetLock(sourceId, assetTypeId, assetId) {
        const lockOption = await this.api.query.nft.locks([sourceId, assetTypeId, assetId])
        if (lockOption.isNone) {
            return {
                isLocked: false,
                reason: ""
            }
        }

        const lockReason = lockOption.unwrap().reasons.bits.toString()
        switch (lockReason) {
            case '1':
                return {
                    isLocked: true,
                    reason: "Selected asset is used by another auction."
                }
            case '2':
                return {
                    isLocked: true,
                    reason: "Selected asset is rented out."
                }

            default:
                console.error("Not supported lockReason")
        }
    }

}
