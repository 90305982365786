import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "react-navi";
import {
    AccountCircle,
    ExpandMore,
    Timeline,
    Store,
    // Settings,
    // PowerSettingsNew,
    ContactSupport
} from '@material-ui/icons';
import { PATH, LINK } from "../../utils/type";
import MenuArrow from './topbarMenuArrow';

export default function Account(props) {
    const { currentUser, accountBtnRef, accountMenuRef, setIsSignInOpen, isAccountOpen, setIsAccountOpen, userService } = props
    const onClick = () => {
        setIsSignInOpen(Boolean(!currentUser))
        if (currentUser) {
            setIsAccountOpen(prev => !prev)
        }
    }

    return <div className={`account ${currentUser ? "" : "no-signin"} ${isAccountOpen ? "selected" : ""}`}>
        <button
            ref={accountBtnRef}
            className="selector-btn"
            onClick={onClick}
        >
            <AccountCircle className="icon" />
            <ExpandMore className="icon icon-expandMore" />
        </button>
        <AccountMenu
            accountMenuRef={accountMenuRef}
            currentUsername={currentUser && currentUser.username}
            userService={userService}
            isAccountOpen={isAccountOpen}
            setIsAccountOpen={setIsAccountOpen}
        />
    </div>
}

function AccountMenu(props) {
    const { accountMenuRef, isAccountOpen, setIsAccountOpen } = props
    // const { accountMenuRef, currentUsername, userService, isAccountOpen, setIsAccountOpen } = props
    const { t } = useTranslation()

    if (!isAccountOpen) {
        return null
    }

    const onClick = () => {
        setIsAccountOpen(false)
    }

    return <div className="selector-menu-outer" ref={accountMenuRef}>
        <MenuArrow />
        <div className="selector-menu">
            {/* <Link className="selector-item userInfo">
                <AccountCircle className="icon" />
                <span className="item-text">{currentUsername} </span>
            </Link>
            <div className="divider" /> */}
            <Link href={`${PATH.LANDING}#home-guide`} onClick={onClick} className="selector-item">
                <ContactSupport className="icon" />
                <span className="item-text">{t("How to use")}</span>
            </Link>
            <a className="selector-item" href={LINK.agenceExplorerURL} onClick={onClick} target="_blank" rel="noopener noreferrer">
                <Timeline className="icon" />
                <span className="item-text">{t("Agence Explorer")}</span>
            </a>
            <Link href={PATH.CREATE_FORGE} onClick={onClick} className="selector-item">
                <Store className="icon" />
                <span className="item-text">{t("Open a forge")}</span>
                {/* <span className="premium">{t("Premium")}</span> */}{/*TODO: Show premium when ready */}
            </Link>
            {/* <div className="divider" />
            <Link href={PATH.ACCOUNT_SETTINGS} onClick={onClick} className="selector-item">
                <Settings className="icon" />
                <span className="item-text">{t("Account Settings")}</span>
            </Link> */}
            {/* <Link className="selector-item" onClick={() => userService && userService.signOut()}>
                <PowerSettingsNew className="icon" />
                <span className="item-text">{t("Sign Out")}</span>
            </Link> */}
        </div>
    </div>
}