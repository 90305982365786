import React from 'react';

export const CARD_TYPE = {
    DEFAULT: '',
    INFO: 'info-card',
    FORM: 'form-card',
    PROGRESS: 'progress-card',
}
export default function Card(props) {
    const { Ref, id, className, cardType, children, onClick } = props

    return (
        <div ref={Ref} id={id} className={`card ${cardType} ${className}`} onClick={onClick} >
            {children}
        </div>
    )
}
Card.defaultProps = {
    Ref: null,
    className: '',
    cardType: CARD_TYPE.DEFAULT,
}
export function CardTitle(props) {
    const { Ref, id, className, children, onClick } = props
    return (
        <div ref={Ref} id={id} className={`card-title ${className}`} onClick={onClick} >
            {children}
        </div >
    )
}
CardTitle.defaultProps = {
    Ref: null,
    className: '',
}
export function CardContent(props) {
    const { Ref, id, className, children, onClick } = props
    return (
        <div ref={Ref} id={id} className={`card-content ${className}`} onClick={onClick} >
            {children}
        </div >
    )
}
CardContent.defaultProps = {
    Ref: null,
    className: '',
}
export function CardAction(props) {
    const { Ref, id, className, children, onClick } = props
    return (
        <div ref={Ref} id={id} className={`card-action ${className}`} onClick={onClick} >
            {children}
        </div >
    )
}
CardAction.defaultProps = {
    Ref: null,
    className: '',
}