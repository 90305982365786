import React, { useState } from 'react';
import '../../styles/views/Authorize.scss';
import { useTranslation } from 'react-i18next';
import { useNavigation, Link } from 'react-navi';
import FormInput from '../../components/form/formInput';
import Button, { BTN_VARIANT } from '../../components/kit/button';
import Card, { CardContent, CardAction, CardTitle, CARD_TYPE } from '../../components/kit/card';
import { PATH, COLOR, ERROR_MSG, INPUT_TYPE, INPUT_ID, SIGNIN_TYPE } from '../../utils/type';
import { handleError } from '../../utils/common';
import { useSafeSet } from '../../utils/hooks';
import { useUsers } from '../../components/providers/users';

export default function SignIn(props) {
    const { setIsSignInOpen, signInType } = props
    const { userService } = useUsers()
    const { t } = useTranslation()

    return (
        <div className="sign-in-container">
            <div className="sign-in-content">
                <SignInCard userService={userService} setIsSignInOpen={setIsSignInOpen} t={t} signInType={signInType} />
            </div>
            <div className="sign-links">
                <Link href={PATH.TOS_NOTICE} className="link-text">{t("Terms of Service")}</Link>
                <Link href={PATH.PRIVACY_NOTICE} className="link-text">{t("Privacy Notice")}</Link>
                <Link href={PATH.PRIVACY_NOTICE} className="link-text">{t("Help")}</Link>
            </div>
        </div>
    )
}

function SignInCard(props) {
    const { userService, setIsSignInOpen, t, signInType } = props
    const [accountError, setAccountError] = useState(ERROR_MSG.NONE)
    const [accountOldValue, setAccountOldValues] = useState("")
    const [accountValue, setAccountValue] = useState("")
    const [passwordValue, setPasswordValue] = useState("")
    const navigation = useNavigation()
    const safeSet = useSafeSet()

    const verifyAccount = () => {
        if (!accountValue || !passwordValue) {
            return ERROR_MSG.INCORRECT
        }
        return ERROR_MSG.NONE
    }

    const onSubmit = async () => {
        let accountNewError = verifyAccount()

        if (!accountNewError) {
            try {
                await userService.signIn(accountValue, passwordValue)

                if (signInType === SIGNIN_TYPE.MODAL) {
                    setIsSignInOpen(false)
                }

                navigation.navigate(PATH.AUCTIONS)
                // #TODO:The check email page isn't complete, so temporarily use home page instead.
                // navigation.navigate(PATH.CHECK_EMAIL, {
                //     body: {
                //         email: values.email,
                //         password: values.password,
                //         type: CHECK_EMAIL_TYPE.SIGN_IN
                //     }
                // })
            } catch (e) {
                accountNewError = ERROR_MSG.INCORRECT
                handleError(e)
            }
        }

        if (accountNewError) {
            safeSet(() => setPasswordValue(""))
        }
        safeSet(() => setAccountOldValues(accountValue))
        safeSet(() => setAccountError(accountNewError))
    }

    const onBlur = () => {
        if (passwordValue || accountValue !== accountOldValue) {
            setAccountError(ERROR_MSG.NONE)
        }
    }

    const handleEmailChange = (value) => {
        setAccountValue(value)
        setAccountError(ERROR_MSG.NONE)
    }

    return (
        <Card className="sign-in-card" cardType={CARD_TYPE.FORM}>
            <CardTitle>{t("Sign in")}</CardTitle>
            <CardContent>
                <FormInput
                    label={t("Email")}
                    id={INPUT_ID.ACCOUNT}
                    placeholder={t("example@mail.com")}
                    defaultValue={accountValue}
                    onChange={handleEmailChange}
                    onBlur={onBlur}
                    onSubmit={onSubmit}
                    errorMessage={t(accountError)}
                    hasError={accountError}
                />
                <FormInput
                    inputType={INPUT_TYPE.PASSWORD}
                    id={INPUT_ID.PASSWORD}
                    label={
                        <>
                            {t("Password")}
                            <Link
                                className="link"
                                tabIndex="-1"
                                href={PATH.FORGOT_PASSWORD}
                                onClick={() => setIsSignInOpen(false)}
                            >
                                {t("Forgot your password?")}
                            </Link>
                        </>}
                    defaultValue={passwordValue}
                    onChange={setPasswordValue}
                    onBlur={onBlur}
                    onSubmit={onSubmit}
                />
            </CardContent>
            <CardAction>
                <Button
                    variant={BTN_VARIANT.CONTAINED}
                    color={COLOR.SECONDARY}
                    onClick={onSubmit}
                >
                    {t("Sign in")}
                </Button>
            </CardAction>
            <div className="create-account-container">
                <span className="text">{t("New to Agence Auction?")}</span>
                <Link className="link text" href={PATH.SIGN_UP} onClick={() => setIsSignInOpen(false)}>{t("Create account")}</Link>
            </div>
        </Card >
    )
}