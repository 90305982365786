import React from 'react';

export default function NaviIcon() {
    return <div className="icon-navi">
        <div className="circle" />
        <div className="long-line" />
        <div className="circle" />
        <div className="short-line" />
        <div className="circle" />
        <div className="long-line" />
    </div>
}