import config from '../config'
import { decodeToken, isObjectInArrayByKey, isTokenExpired, getObjectIndexByKey } from '../utils/common'
import { ARMOR_METHOD, LOCAL_STORAGE_KEYS, ARRAY_KEY, ERROR_CODE } from '../utils/type'
import { agenceAccountService } from './agenceAccount'

export const ERROR_CODES = {
    INVALID_USER_PASSWORD: 474003,
    MISSING_FIELD: -32602,
    DUPLICATE_SIGN_UP: 474003
}

/***
 * Sends a request to the Armor API
 * 
 * @returns The response of the request
 * @param {string} method The method for Armor to execute.
 * @param {string[]} params The parameters for this call.
 * @param {any} token The refresh token to use.
 * 
 */
const sendArmorRequest = async (method, params, token) => {

    // Gen random id
    const id = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)

    const msg = JSON.stringify({
        'jsonrpc': '2.0',
        'id': id,
        'method': method,
        'params': params
    })
    const headers = new Headers({ 'Content-Type': 'application/json' })

    if (token) {
        headers.append('Authorization', 'Bearer ' + token)
    }

    let res = await fetch(config.SERVER_URL, {
        method: 'POST',
        headers: headers,
        body: msg
    })
    res = await res.json()

    // TODO Add error code?
    if (!res) { throw Error('Empty Response') }

    // Wrap and re-throw the error
    if (res.error) {
        let err = Error(res.error.message)
        err.code = res.error.code
        throw err
    }

    return res.result
}

// A simple "authentication" service that just saves the 
// user's name in a cookie.
export const userService = {
    async signIn(email, password) {
        const result = await sendArmorRequest(ARMOR_METHOD.USER_SIGN_IN, [email, password])
        window.localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENT_USER, JSON.stringify(result))
        this.currentUser = result
        const firstWallet = result && result.userState && result.userState.agenceAddresses && result.userState.agenceAddresses[0]

        if (firstWallet) {
            this.currentWallet = firstWallet
            localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENT_WALLET, JSON.stringify(this.currentWallet))
        }
        this.subWallets()
    },

    async signUp(username, email, password) {
        return await sendArmorRequest(ARMOR_METHOD.USER_SIGN_UP, [username, email, password])
    },

    newRefreshToken() {
        this.assertLoggedIn()
        const refreshToken = this.currentUser.refreshToken
        return sendArmorRequest(ARMOR_METHOD.USER_NEW_REFRESH_TOKEN, [], refreshToken)
    },

    newToken() {
        this.assertLoggedIn()
        const refreshToken = this.currentUser.refreshToken
        return sendArmorRequest(ARMOR_METHOD.USER_NEW_TOKEN, [], refreshToken)
    },

    sendForgotPasswordMail(email) {
        const mailAddress = 'inquery@aetheras.io'
        const subject = 'Forgot%20Password'
        const body = `Email%20Address:%20${email}%0A%0A================================%0A%0Athis%20mail%20is%20generated%20automatically,%20%20please%20just%20send%20directly.`
        window.location.assign(`mailto:${mailAddress}?subject=${subject}&body=${body}`)
    },

    changeUsername(username) {
        //#TODO: Now it's just mock
        console.log('change username: ' + username)
    },

    changeMail(mail) {
        //#TODO: Now it's just mock
        console.log('change mail' + mail)
    },

    changePassword(password) {
        //#TODO: Now it's just mock
        console.log('change password' + password)
    },

    checkUsername(username) {
        //#TODO: Now it's just mock
        console.log('change username: ' + username)
    },

    checkPassword(password) {
        //#TODO: Now it's just mock
        this.assertLoggedIn()
        const token = this.currentUser.token
        const jwt = decodeToken(token)

        return sendArmorRequest(
            ARMOR_METHOD.USER_UPDATE_PASSWORD,
            [jwt.sub, password, password]
        )
    },

    updatePassword(oldPassword, newPassword) {
        this.assertLoggedIn()
        const token = this.currentUser.token
        const jwt = decodeToken(token)

        return sendArmorRequest(
            ARMOR_METHOD.USER_UPDATE_PASSWORD,
            [jwt.sub, oldPassword, newPassword]
        )
    },

    async addWallet(keypair, walletExport) {

        this.assertLoggedIn()

        const address = walletExport.address

        this.currentUser.userState.agenceAddresses.push(address)
        localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENT_USER, JSON.stringify(this.currentUser))

        walletExport.name = this.currentUser.name

        if (!this.currentWallet) {
            this.currentWallet = address
            localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENT_WALLET, JSON.stringify(this.currentWallet))
        }

        this.addWalletInMapping(walletExport)

        const unsubs = this.unsubs

        agenceAccountService.subscribeAgenceAccount(address, (account) => {
            account.exist = true
            account.name = walletExport.name

            if (!this.wallets) {
                this.wallets = [account]
            } else {
                const walletIndex = this.wallets.findIndex(wallet => wallet.address === address)
                if (walletIndex > -1) {
                    this.wallets[walletIndex] = account
                } else {
                    this.wallets.push(account)
                }
            }

            this.callback && this.callback()
        }).then(unsub => {
            unsubs.set(address, unsub)
        }).catch(err => {
            console.log(err)
        })
    },

    async setWalletName(address, name) {
        this.assertLoggedIn()
        const userId = this.currentUser.userState.aggregateId
        const walletMapping = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.WALLET_MAPPINGS))

        const selectedWallets = walletMapping && walletMapping[userId]

        if (selectedWallets) {
            const selectedWallet = selectedWallets.find(wallet => wallet.address === address)
            const wallet = this.wallets.find(wallet => wallet.address === address)
            selectedWallet.name = name
            wallet.name = name
        }

        try {
            localStorage.setItem(LOCAL_STORAGE_KEYS.WALLET_MAPPINGS, JSON.stringify(walletMapping))
        } catch (err) {
            console.log(err)
        }
        this.callback && this.callback()
    },

    async deleteWallet(address) {
        this.assertLoggedIn()


        this.currentUser.userState.agenceAddresses = this.currentUser.userState.agenceAddresses.filter((value) => { return value !== address })

        localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENT_USER, JSON.stringify(this.currentUser))
        this.removeWalletInMapping(address)

        const newAgenceAddresses = this.currentUser.userState.agenceAddresses

        if (newAgenceAddresses && !newAgenceAddresses.includes(this.currentWallet)) {
            const firstWallet = newAgenceAddresses[0]

            if (!firstWallet) {
                this.currentWallet = null
                localStorage.removeItem(LOCAL_STORAGE_KEYS.CURRENT_WALLET)
            }
            else {
                this.currentWallet = firstWallet
                localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENT_WALLET, JSON.stringify(this.currentWallet))
            }
        }

        if (this.unsubs && this.unsubs.has(address)) {
            this.unsubs.get(address)()
            this.unsubs.delete(address)
        }

        const deleteIndex = this.wallets.findIndex(wallet => wallet.address === address)
        if (deleteIndex > -1) {
            this.wallets.splice(deleteIndex, 1)
        }

        this.callback && this.callback()
    },

    signOut() {
        delete this.currentUser
        delete this.currentWallet
        delete this.wallets
        this.cleanupState()
        localStorage.removeItem(LOCAL_STORAGE_KEYS.CURRENT_USER)
        localStorage.removeItem(LOCAL_STORAGE_KEYS.CURRENT_WALLET)
        this.callback && this.callback()
    },

    addWalletInMapping(wallet) {
        this.assertLoggedIn()
        const userId = this.currentUser.userState.aggregateId
        let walletMapping = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.WALLET_MAPPINGS))
        if (!walletMapping) {
            walletMapping = {}
            walletMapping[userId] = []
        }

        let wallets = walletMapping[userId]

        if (!wallets) {
            wallets = []
            walletMapping[userId] = wallets
        } else if (isObjectInArrayByKey(wallets, ARRAY_KEY.ADDRESS, wallet.address)) {
            const idx = getObjectIndexByKey(wallets, ARRAY_KEY.ADDRESS, wallet.address)
            if (idx === -1) {
                return
            }
            wallets[idx] = wallet
        }
        else {
            wallets.push({ ...wallet, name: "" })
        }

        try {
            localStorage.setItem(LOCAL_STORAGE_KEYS.WALLET_MAPPINGS, JSON.stringify(walletMapping))
        } catch (err) {
            console.log(err)
        }
    },

    removeWalletInMapping(address) {
        this.assertLoggedIn()
        const userId = this.currentUser.userState.aggregateId
        let walletMapping = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.WALLET_MAPPINGS))
        if (!walletMapping) {
            return
        }

        let wallets = walletMapping[userId]
        if (!wallets) {
            return
        }

        walletMapping[userId] = wallets.filter(w => w.address !== address)

        try {
            localStorage.setItem(LOCAL_STORAGE_KEYS.WALLET_MAPPINGS, JSON.stringify(walletMapping))
        } catch (err) {
            console.log(err)
        }
    },

    fetchWalletFromStorage(address) {
        this.assertLoggedIn()

        let walletMapping
        try {
            walletMapping = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.WALLET_MAPPINGS))
        } catch (err) {
            console.log(err)
        }

        if (!walletMapping) {
            return null
        }

        const wallets = walletMapping[this.currentUser.userState.aggregateId]
        const target = wallets.find(w => w.address === address)

        return target
    },

    setCurrentWallet(currentWallet) {
        this.currentWallet = currentWallet
        localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENT_WALLET, JSON.stringify(this.currentWallet))
        this.callback && this.callback()
    },

    async getToken() {
        this.assertLoggedIn()
        let token = this.currentUser.token
        if (isTokenExpired(token)) {
            token = await this.newToken()
            this.currentUser.token = token
        }
        return token
    },

    async getAccount(address) {
        try {
            return await agenceAccountService.getAgenceAccount(address)
        } catch (err) {
            // if (err.code !== ErrorCode.DATA_NOT_FOUND) {
            console.log(err)
            // }
            return null
        }
    },

    async subWallets() {
        if (!this.currentWallet) {
            this.callback && this.callback()
            return
        }

        const userId = this.currentUser.userState.aggregateId
        const agenceAddresses = this.currentUser.userState.agenceAddresses

        const unsubs = new Map()
        for (let i = 0; i < agenceAddresses.length; i++) {
            const address = agenceAddresses[i]

            agenceAccountService.subscribeAgenceAccount(address, (account) => {

                let walletMapping
                try {
                    walletMapping = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.WALLET_MAPPINGS))
                } catch (err) {
                    console.log(err)
                }
                const selectedWallets = walletMapping && walletMapping[userId]

                const matchedWallet = selectedWallets && selectedWallets.find(wallet => wallet.address === address)
                account.exist = Boolean(matchedWallet)
                account.name = (matchedWallet && matchedWallet.name) || ""

                if (!this.wallets) {
                    this.wallets = [account]
                } else {
                    const walletIndex = this.wallets.findIndex(wallet => wallet.address === address)
                    if (walletIndex > -1) {
                        this.wallets[walletIndex] = account
                    } else {
                        this.wallets.push(account)
                    }
                }

                this.callback && this.callback()
            }).then(unsub => {
                unsubs.set(address, unsub)
            }).catch(err => {
                console.log(err)
            })
        }

        this.unsubs = unsubs
    },

    getState() {
        if (!this.currentUser) {
            try {
                let storageUser = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEYS.CURRENT_USER))

                if (!storageUser) {
                    // Create storageUser

                    // Shared local user
                    storageUser = {
                        userState: {
                            aggregateId: "1",
                            agenceAddresses: [],
                            email: "test@test.com",
                            username: "Tester",
                            version: 4
                        },
                    }
                    window.localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENT_USER, JSON.stringify(storageUser))

                }
                this.currentUser = storageUser
                // if (!storageUser) {
                //     this.signOut()
                //     return
                // }
                // if (!isTokenExpired(storageUser.refreshToken)) {
                //     this.currentUser = storageUser
                // }
                // else {
                //     this.signOut()
                //     return
                // }
            } catch (e) {
                console.log(e)
            }
        }
        this.currentWallet = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEYS.CURRENT_WALLET))
        if (!this.currentWallet && this.currentUser) {
            this.currentWallet = (this.currentUser.userState.agenceAddresses && this.currentUser.userState.agenceAddresses[0]) || null
        }

        //Start subscription to wallets
        this.subWallets()
        this.callback && this.callback()
    },

    cleanupState() {
        if (!this.unsubs) {
            return
        }

        const keys = this.unsubs.keys
        for (let i = 0; i < keys.length; i++) {
            const address = keys[i]
            this.unsubs.has(address) && this.unsubs.get(address)()
        }

        this.unsubs = new Map()
    },

    assertLoggedIn() {
        if (!this.currentUser) {
            let err = Error("currentUser not found")
            err.code = ERROR_CODE.CURRENT_USER_NOTFOUND
            throw err
        }
    },

    subscribe(callback) {
        this.callback = () => {
            if (callback) {
                callback({
                    currentUser: (this.currentUser && this.currentUser.userState) || null,
                    currentWallet: this.currentWallet || null,
                    wallets: this.wallets || null,
                })
            }
        }
        return () => { this.callback = undefined }
    },
}
