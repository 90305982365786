import React, { useState, useRef, useEffect } from 'react';
import { KeyboardArrowDown } from '@material-ui/icons';
import { Popper, MenuList, MenuItem, ClickAwayListener } from '@material-ui/core';
import { DROPDOWN_TYPE, DROPDOWN_MINIMUN_WIDTH } from '../../utils/type';

export default function Dropdown(props) {
    const { dropdownType, items, defaultSelected, onSelect, addItem, placement, disabled } = props
    const [isOpen, setIsOpen] = useState(false)
    const [selected, setSelected] = useState(defaultSelected)

    const anchorRef = useRef(null)

    useEffect(() => {
        setSelected(defaultSelected)
    }, [defaultSelected, setSelected])

    const handleListKeyDown = (e) => {
        if (e.key === 'Tabs') {
            e.preventDefault()
            setIsOpen(false)
        }
    }

    const handleItemClick = (idx) => {
        if (idx !== selected) {
            setSelected(idx)
            onSelect(idx)
        }
        setIsOpen(false)
    }

    const handleClickAway = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }
        setIsOpen(false)
    }

    const anchorWidth = anchorRef.current ? `${anchorRef.current.clientWidth}px` : DROPDOWN_MINIMUN_WIDTH

    return (
        <>
            <button
                className={`btn dropdown-btn ${isOpen ? 'pressed' : ''} ${dropdownType || ""}`}
                onClick={() => setIsOpen(prev => !prev)}
                ref={anchorRef}
                disabled={disabled}
            >
                <span className="dropdown-label">
                    {items[selected]}
                    <span className="arrow-down"><KeyboardArrowDown /></span>
                </span>
            </button>
            <Popper
                className="dropdown-popper"
                open={isOpen}
                anchorEl={anchorRef.current}
                placement={placement ? placement : 'bottom-start'}
                modifiers={{ flip: { enabled: false } }}
            >
                <ClickAwayListener onClickAway={handleClickAway}>
                    <MenuList
                        className={`dropdown-menu ${dropdownType}`}
                        style={{ minWidth: anchorWidth }}
                        autoFocusItem={isOpen}
                        onKeyDown={handleListKeyDown}
                    >
                        <MenuItems
                            itemType={dropdownType}
                            addItem={addItem}
                            items={items}
                            handleItemClick={handleItemClick}
                            selected={selected}
                        />
                    </MenuList>
                </ClickAwayListener>
            </Popper>
        </>
    )
}

Dropdown.defaultProps = {
    type: "",
    items: [],
    defaultSelected: 0,
}

export function MenuItems(props) {
    const { itemType, items, addItem, selected, handleItemClick } = props
    const [focused, setFocused] = useState(selected)

    if (itemType === DROPDOWN_TYPE.ADD_ITEM) {
        return (
            <div className="dropdown-menuItems-container">
                {items.map((item, index) => (
                    <MenuItemWithType
                        key={index}
                        index={index}
                        item={item}
                        onSelect={handleItemClick}
                        focused={focused}
                        setFocused={setFocused}
                    />
                ))}
                <div className="divider" />
                <MenuItemWithType
                    index={-1}
                    item={addItem}
                    onSelect={handleItemClick}
                    focused={focused}
                    setFocused={setFocused}
                />
            </div >
        )
    }

    return (
        <div className="dropdown-menuItem">
            {items.map((item, index) => (
                <MenuItemWithType
                    key={index}
                    index={index}
                    item={item}
                    onSelect={handleItemClick}
                    focused={focused}
                    setFocused={setFocused}
                />
            ))}
        </div>
    )
}

export function MenuItemWithType(props) {
    const { index, item, onSelect, focused, setFocused } = props
    const indexClass = index === -1 ? ' dropdown-addItem-menuItem' : ''
    const focusClass = index === focused ? ' focused' : ''

    return <MenuItem
        className={`dropdown-menuItem ${indexClass} ${focusClass}`}
        key={index}
        onMouseEnter={() => setFocused(index)}
        onClick={() => onSelect(index)}
    >
        <span className="dropdown-menuItem-label">{item}</span>
    </MenuItem>
}
