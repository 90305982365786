import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigation, Link } from 'react-navi';
import { AccountBalanceWallet } from '@material-ui/icons';
import { shortAddr, tailAddr, formatCurrencyNumber } from '../../utils/common';
import { IMAGE_PATH, PATH } from '../../utils/type';
import MenuArrow from './topbarMenuArrow';
import IconMana from '../icon/mana';

export default function Wallet(props) {
    const {
        userService,
        currentUser,
        walletBtnRef,
        walletMenuRef,
        setIsSignInOpen,
        setIsWalletOpen,
        isWalletOpen,
        isAccountOpen,
        currentWallet,
        wallets,
        currentPathname
    } = props

    const timeoutId = useRef(null)
    const navigation = useNavigation()
    const { t } = useTranslation()

    const onClick = () => {
        if (!currentUser) {
            setIsSignInOpen(true)
        }
        else if (!currentWallet) {
            navigation.navigate(PATH.ADD_NEW_WALLET)
        }
        else {
            setIsWalletOpen(true)
        }
    }

    const onMouseEnter = () => {
        if (!currentUser || !currentWallet || isAccountOpen) {
            return
        }
        clearTimeout(timeoutId.current)
        setIsWalletOpen(true)
    }

    const onMouseLeave = () => {
        timeoutId.current = setTimeout(() => setIsWalletOpen(false), 500)
    }

    return (
        <div className={`wallet ${currentWallet ? "" : "no-wallet"} ${currentUser ? "" : "no-signin"} ${isWalletOpen ? "selected" : ""}`}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <button ref={walletBtnRef} className="selector-btn" onClick={onClick}>
                <AccountBalanceWallet className="icon" />
                <span className="address">
                    {currentWallet ? tailAddr(currentWallet) : t("No Wallet")}
                </span>
            </button>
            {isWalletOpen &&
                <div className="selector-menu-outer" ref={walletMenuRef}>
                    <div className="selector-menu-hover-area" />
                    <MenuArrow />
                    <WalletMenu
                        t={t}
                        navigation={navigation}
                        userService={userService}
                        isWalletOpen={isWalletOpen}
                        setIsWalletOpen={setIsWalletOpen}
                        wallets={wallets}
                        currentWallet={currentWallet}
                        currentPathname={currentPathname}
                    />
                </div>
            }
        </div>
    )
}

function WalletMenu(props) {
    const { userService, isWalletOpen, currentWallet, wallets, setIsWalletOpen, currentPathname, t } = props

    const selectWallet = (wallet) => {
        if (!USING_WALLET_PATHS.includes(currentPathname)) {
            userService.setCurrentWallet(wallet)
            setIsWalletOpen(false)
        }
    }

    if (isWalletOpen) {
        return <div className="selector-menu">
            <div className="wallet-body">
                {wallets && wallets.map((wallet, index) => <div className="selector-item-outer" key={index}>
                    <li className={`selector-item ${wallet.address === currentWallet ? "current" : ""}`} onClick={() => selectWallet(wallet.address)}>
                        <div className="wallet-profile">
                            <img className="profile-img" src={IMAGE_PATH.ARMOR_ICON} alt={t("img profile")} />
                        </div>
                        <div className="wallet-content">
                            <div className="wallet-text wallet-name">{wallet.name ? wallet.name : t("Wallet name")}</div>
                            <div className="wallet-text wallet-address">{shortAddr(wallet.address)}</div>
                            <div className="wallet-text wallet-coins"><IconMana className="icon" />{formatCurrencyNumber(wallet.freeBalance)}</div>
                        </div>
                    </li>
                    <div className="divider" />
                </div>
                )}
            </div>
            <div className="wallet-footer">
                <div className="divider" />
                <Link href={PATH.WALLET_SETTINGS} className="selector-item" onClick={() => setIsWalletOpen(false)}>
                    <AccountBalanceWallet className="icon" />
                    <span className="item-text">{t("Wallet Settings")}</span>
                </Link>
            </div>
        </div>
    }
}


const USING_WALLET_PATHS = [
    PATH.CREATE_AUCTION,
    PATH.MINT_ASSETS_HASH,
    PATH.MINT_ASSETS_JSON
]