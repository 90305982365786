import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "react-navi";
import {
    SportsEsportsSharp
} from '@material-ui/icons';
import MenuArrow from './topbarMenuArrow';
import { UNITY_GAMES } from '../../utils/type';

export default function Games(props) {
    const { currentWallet } = props
    const [isGamesOpen, setIsGamesOpen] = useState(false)
    const { t } = useTranslation()
    const gameBtnRef = useRef(null)
    const gameMenuRef = useRef(null)
    const timeoutId = useRef(null)

    useEffect(() => {
        const onMenuClose = (event) => {
            const isGameMenuClicked = gameMenuRef.current && gameMenuRef.current.contains(event.target)
            const isGameBtnClicked = gameBtnRef.current && gameBtnRef.current.contains(event.target)

            if (!isGameBtnClicked && !isGameMenuClicked) {
                setIsGamesOpen(false)
            }
        }

        document.addEventListener('click', onMenuClose)
        return () => {
            document.removeEventListener('click', onMenuClose)
        }
    }, [])

    const onMouseEnter = () => {
        clearTimeout(timeoutId.current)
        setIsGamesOpen(true)
    }

    const onMouseLeave = () => {
        timeoutId.current = setTimeout(() => setIsGamesOpen(false), 500)
    }

    return <div
        className={'game-tab navi-link selector-item'}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}>
        <button
            ref={gameBtnRef}

        >
            <div className="navi-icon">
                <SportsEsportsSharp className="icon" />
            </div>
            <span className="h6 navi-text">{t('Games')}</span>
        </button>
        <GamesMenu
            currentWallet={currentWallet}
            isGamesOpen={isGamesOpen}
            setIsGamesOpen={setIsGamesOpen}
            gameMenuRef={gameMenuRef}
        />
    </div>
}

function GamesMenu(props) {
    const { currentWallet, isGamesOpen, gameMenuRef } = props

    if (!isGamesOpen) {
        return null
    }

    const onGameClick = (gameURL) => (e) => {
        e.preventDefault()
        window.open(`${gameURL}${(currentWallet) ? '?address="' + currentWallet + '"' : ''}`)
    }

    const gameLinks = Object.values(UNITY_GAMES).map(game =>
        <Link key={game.name} className="selector-item" onClick={onGameClick(game.link)}>
            <span className="item-text">{game.name} </span>
        </Link>)

    return <div className="selector-menu-outer" ref={gameMenuRef}>
        <MenuArrow />
        <div className="selector-menu">
            {gameLinks}
        </div>
    </div>
}