import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button as MuiButton, Tooltip, Zoom } from '@material-ui/core';
import { QuestionAnswer, KeyboardArrowRight, KeyboardArrowLeft, Info } from '@material-ui/icons';
import { LINK, COLOR, ARROW_TYPE } from '../../utils/type';

export const BTN_SIZE = {
    DEFAULT: '',
    LARGE: 'large'
}

export const BTN_VARIANT = {
    TEXT: 'text',
    ICON: ' icon',
    CONTAINED: 'contained',
    OUTLINED: 'outlined',
}

export default function Button(props) {
    const {
        className,
        size,
        variant,
        type,
        color,
        rounded,
        prefixIcon,
        suffixIcon,
        children,
        disabled,
        tabIndex,
        onClick,
        href,
        Ref,
    } = props

    return (
        <MuiButton
            className={'btn' +
                ` ${className}` +
                ` ${variant}` +
                ` ${color}` +
                ` ${size}` +
                (rounded ? ' rounded' : '') +
                (prefixIcon ? ' prefix' : '') +
                (suffixIcon ? ' suffix' : '') +
                (disabled ? ' disabled' : '')
            }
            disabled={disabled}
            onClick={onClick}
            tabIndex={tabIndex}
            href={href}
            type={type}
            ref={Ref}
        >
            {prefixIcon && prefixIcon}
            {children}
            {suffixIcon && suffixIcon}
        </MuiButton>
    )
}

Button.defaultProps = {
    className: '',
    size: BTN_SIZE.DEFAULT,
    variant: BTN_VARIANT.TEXT,
    color: COLOR.DEFAULT,
    rounded: false,
    prefixIcon: null,
    suffixIcon: null,
    disabled: false,
    tabIndex: null,
    href: null,
    ref: null,
}

export function ButtonGroup({ children }) {
    const items = children.map((button, index) => (
        <div key={index} className="group-item">
            {button}
        </div>
    ))
    return (
        <div className="btn-group">
            {items}
        </div>
    )
}

export function DiscordButton() {
    const { t } = useTranslation()
    return (
        <div className="btn-discord">
            <QuestionAnswer className="chat-icon" />
            <a className="chat-link" href={LINK.discordURL} target="_blank" rel="noopener noreferrer">
                <img className="chat-img" src="/images/icon-discord.svg" alt={t("icon discord")} />
                {t("Chat with us on Discord")}
            </a>
        </div>
    )
}

export function ArrowButton(props) {
    const { color, size, onClick, disabled, arrowType } = props
    const arrow = arrowType === ARROW_TYPE.NEXT ? <KeyboardArrowRight /> : <KeyboardArrowLeft />

    return (
        <Button
            className={`btn-arrow ${color} ${size}`}
            variant={BTN_VARIANT.OUTLINED}
            onClick={onClick}
            disabled={disabled}
        >
            {arrow}
        </Button>
    )
}

ArrowButton.defaultProps = {
    color: COLOR.DEFAULT,
    size: BTN_SIZE.DEFAULT,
    disabled: false
}

export function InfoButton(props) {
    const { onClick, tooltip, disabled } = props
    const [hover, setHover] = useState(false)

    return (
        <Tooltip
            classes={{ tooltip: `tooltip ${COLOR.DARK}` }}
            open={hover && !disabled}
            title={tooltip}
            placement="top"
            TransitionComponent={Zoom}
        >
            <MuiButton
                className="btn btn-info icon primary"
                disabled={disabled}
                onClick={onClick}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <Info />
            </MuiButton>
        </Tooltip>
    )
}