import { ACTION_TYPE, AUCTION_KIND } from "../utils/type";
import { toCurrency } from "../utils/common";


export const agenceService = {
    setup(api) {
        this.api = api
    },

    generateTx(summary) {
        switch (summary.type) {
            case ACTION_TYPE.OPEN_FORGE:
                return this.api.tx.nft.createForge()

            case ACTION_TYPE.CREATE_ASSET_KIND:
                return this.api.tx.nft.createClass(summary.args.source_id, summary.args.asset_type_id, summary.args.data_uri, summary.args.data_hash)

            case ACTION_TYPE.CREATE_ASSET:
                return this.api.tx.nft.createAsset(summary.args.dest, summary.args.source_id, summary.args.asset_type_id, summary.args.metadata, summary.args.data)

            case ACTION_TYPE.NEW_AUCTION:
                let auctionKind

                switch (Object.values(AUCTION_KIND)[summary.args.auctionKindIndex]) {
                    case AUCTION_KIND.BASIC:
                        auctionKind = this.api.createType("AgenceAuctionsAuctionKind", AUCTION_KIND.BASIC)
                        break
                    case AUCTION_KIND.WITH_BUY_NOW:
                        auctionKind = this.api.createType("AgenceAuctionsAuctionKind", { "WithBuyNow": { "price": toCurrency(summary.args.buyNowPrice) } })
                        break
                    case AUCTION_KIND.BUY_ONLY:
                        auctionKind = this.api.createType("AgenceAuctionsAuctionKind", AUCTION_KIND.BUY_ONLY)
                        break
                    case AUCTION_KIND.REVERSE:
                        auctionKind = this.api.createType("AgenceAuctionsAuctionKind", { "Reverse": { "decay": summary.args.decay, "decay_rate": summary.args.decayRate } })
                        break
                    default:
                        console.log("Unsupport AuctionKind")
                }

                const auctionTx = this.api.tx.auctions.createAuction(auctionKind, summary.args.asset, toCurrency(summary.args.startPrice), summary.args.duration)

                return auctionTx

            case ACTION_TYPE.PLACE_BID:
                return this.api.tx.auctions.bid(summary.args.auction_id, toCurrency(summary.args.price))

            case ACTION_TYPE.BUY_IT_NOW:
                return this.api.tx.auctions.buy(summary.args.auction_id)

            default:
                console.log("Unsupported ACTION_TYPE: " + summary.type)
        }
    }
}
