import React from 'react';
import { compose, mount, redirect, withView, route, map } from 'navi';
import { View, NotFoundBoundary } from 'react-navi';
import Config from '../config';
import { PATH, ENVIRONMENT } from '../utils/type';
import Layout from '../views/layout/Layout';
import { SIGNIN_TYPE } from '../utils/type';
import NotFound from '../views/NotFound';

const authorizedRoute = role => matcher => map((_, context) => {
    const { userState } = context
    const { currentUser } = userState

    if (role) {
        if (currentUser.roles && currentUser.roles.includes(role)) {
            return matcher
        }
        return redirect(PATH.HOME)
    } else {
        if (currentUser) {
            return matcher
        }
        return redirect(PATH.HOME)
    }
})

const devOnly = () => matcher => map(async (_, context) => {
    if (Config.ENVIRONMENT === ENVIRONMENT.DEVELOPMENT) {
        return matcher
    }
    return redirect(PATH.HOME)
})

export default compose(
    withView((_, context) =>
        // layout
        <Layout
            currentUser={context.userState.currentUser}
            currentWallet={context.userState.currentWallet}
            wallets={context.userState.wallets}
            windowWidth={context.windowWidth}
            layoutWrapperTop={context.layoutWrapperTop}
        >
            <NotFoundBoundary render={() => <NotFound />}>
                < View />
            </NotFoundBoundary>
        </Layout >
    ),
    mount({
        [PATH.HOME]: map(() => redirect(PATH.LANDING)),

        [PATH.LANDING]: route({
            getView: async (_, context) => {
                let { default: VisitorHome } = await import('../views/authorize/VisitorHome')
                return <VisitorHome
                    currentWallet={context.currentWallet}
                    currentUser={context.userState.currentUser}
                />
            }
        }),

        [PATH.SIGN_IN]: map(async (_, context) => {
            if (context.userState.currentUser) {
                return redirect(PATH.HOME)
            }
            return route({
                getView: async (_, context) => {
                    let { default: SignIn } = await import('../views/authorize/SignIn')
                    return <SignIn
                        signInType={SIGNIN_TYPE.ROUTES}
                    />
                }
            })
        }),

        [PATH.SIGN_UP]: map(async (_, context) => {
            if (context.userState.currentUser) {
                return redirect(PATH.HOME)
            }
            return route({
                getView: async (_, context) => {
                    let { default: SignUp } = await import('../views/authorize/SignUp')
                    return <SignUp />
                }
            })
        }),

        [PATH.CHECK_EMAIL]: map(async (req, context) => {
            if (context.userState.currentUser || !req.body) {
                return redirect(PATH.HOME)
            }
            return route({
                getView: async (req, context) => {
                    let { default: CheckEmail } = await import('../views/authorize/CheckEmail')
                    return <CheckEmail
                        email={req.body.email}
                        password={req.body.password}
                        type={req.body.type}
                    />
                }
            })
        }),

        [PATH.CHANGE_EMAIL]: map(async (req, context) => {
            if (context.userState.currentUser || !req.body) {
                return redirect(PATH.HOME)
            }
            return route({
                getView: async (req, context) => {
                    let { default: ChangeEmail } = await import('../views/authorize/ChangeEmail')
                    return <ChangeEmail
                        email={req.body.email}
                        password={req.body.password}
                    />
                }
            })
        }),

        [PATH.FORGOT_PASSWORD]: map(async (_, context) => {
            if (context.userState.currentUser) {
                return redirect(PATH.HOME)
            }
            return route({
                getView: async (_, context) => {
                    let { default: ForgotPassword } = await import('../views/authorize/ForgotPassword')
                    return <ForgotPassword />
                }
            })
        }),

        [PATH.RESET_PASSWORD]: map(async (_, context) => {
            if (context.userState.currentUser) {
                return redirect(PATH.HOME)
            }
            return route({
                getView: async (_, context) => {
                    let { default: ResetPassword } = await import('../views/authorize/ResetPassword')
                    return <ResetPassword />
                }
            })
        }),

        [PATH.ACCOUNT_SETTINGS]: authorizedRoute()(
            route({
                title: 'Account Settings',
                getView: async (_, context) => {
                    let { default: AccountSettings } = await import('../views/profile/AccountSettings');
                    return <AccountSettings
                        currentUser={context.userState.currentUser}
                    />
                },
            })
        ),

        [PATH.WALLET_SETTINGS]: authorizedRoute()(
            route({
                title: 'Wallet Settings',
                getView: async (_, context) => {
                    let { default: WalletSettings } = await import('../views/profile/WalletSettings')
                    return <WalletSettings
                        windowWidth={context.windowWidth}
                        currentWallet={context.userState.currentWallet}
                        wallets={context.userState.wallets}
                    />
                },
            })
        ),

        [PATH.ADD_NEW_WALLET]: authorizedRoute()(
            route({
                getView: async (_, context) => {
                    let { default: AddNewWallet } = await import('../views/wallet/AddNewWallet')
                    return <AddNewWallet />
                }
            })
        ),

        [PATH.TOS_NOTICE]: route({
            getView: () => import('../views/legal/TermsOfService')
        }),

        [PATH.PRIVACY_NOTICE]: route({
            getView: () => import('../views/legal/Privacy')
        }),

        [PATH.IMPORT_WALLET]: authorizedRoute()(
            route({
                getView: async (_, context) => {
                    let { default: ImportWallet } = await import('../views/wallet/ImportWallet')
                    return <ImportWallet />
                }
            })
        ),
        [PATH.RESTORE_WALLET]: authorizedRoute()(
            route({
                getView: async (req, context) => {
                    let { default: RestoreWallet } = await import('../views/wallet/RestoreWallet')
                    return <RestoreWallet
                        wallet={req.body && req.body.wallet}
                    />
                }
            })
        ),

        [PATH.CREATE_PASSWORD]: authorizedRoute()(
            route({
                getView: async (req, context) => {
                    let { default: CreatePassword } = await import('../views/wallet/CreatePassword')
                    return <CreatePassword
                        prevPassword={req.body && req.body.password}
                        prevPhrase={req.body && req.body.phrase}
                    />
                }
            })
        ),

        [PATH.BACKUP_PHRASE]: map(async (req) => {
            if (!req.body || !req.body.password) {
                return authorizedRoute()(
                    redirect(PATH.CREATE_PASSWORD)
                )
            }
            return authorizedRoute()(
                route({
                    getView: async (req, context) => {
                        let { default: BackupPhrase } = await import('../views/wallet/BackupPhrase')
                        return <BackupPhrase
                            password={req.body.password}
                            prevPhrase={req.body.phrase}
                        />
                    }
                })
            )
        }),

        [PATH.CONFIRM_PHRASE]: map(async (req) => {
            if (!req.body || (!req.body.password || !req.body.phrase)) {
                return authorizedRoute()(
                    redirect(PATH.BACKUP_PHRASE)
                )
            }
            return authorizedRoute()(
                route({
                    getView: async (req, context) => {
                        let { default: ConfirmPhrase } = await import('../views/wallet/ConfirmPhrase')
                        return <ConfirmPhrase
                            password={req.body.password}
                            phrase={req.body.phrase}
                        />
                    }
                })
            )
        }),

        [PATH.WALLET_CREATED]: map(async (req) => {
            if (!req.body) {
                return authorizedRoute()(
                    redirect(PATH.WALLET_SETTINGS)
                )
            }
            return authorizedRoute()(
                route({
                    getView: async (req, _) => {
                        let { default: WalletCreated } = await import('../views/wallet/WalletCreated')
                        return <WalletCreated address={req.body.address} />
                    }
                })
            )
        }),

        [PATH.SELECT_ASSET]: authorizedRoute()(
            route({
                getView: async (req, context) => {
                    let { default: SelectAsset } = await import('../views/auction/SelectAsset')
                    return <SelectAsset
                        setLayoutWrapperTop={context.setLayoutWrapperTop}
                        currentWallet={context.userState.currentWallet}
                        windowWidth={context.windowWidth}
                        pageNo={req.params.p}
                    />
                }
            }))
        ,

        [PATH.SELECT_ASSET_SEARCH]: map(async (req) => {
            if (req.params.q === undefined || req.params.q === "") {
                return authorizedRoute()(
                    redirect(PATH.SELECT_ASSET)
                )
            }
            return authorizedRoute()(
                route({
                    getView: async (req, context) => {
                        let { default: SelectAsset } = await import('../views/auction/SelectAsset')

                        return <SelectAsset
                            setLayoutWrapperTop={context.setLayoutWrapperTop}
                            currentWallet={context.userState.currentWallet}
                            windowWidth={context.windowWidth}
                            pageNo={req.params.p}
                            query={req.params.q}
                        />
                    }
                })
            )
        }),

        [PATH.CREATE_AUCTION]: map(async (req) => {
            if (!req.body || !req.body.selectedAsset) {
                return authorizedRoute()(
                    redirect(PATH.SELECT_ASSET)
                )
            }
            return authorizedRoute()(
                route({
                    getView: async (req, context) => {
                        let { default: CreateAuction } = await import('../views/auction/CreateAuction')
                        return <CreateAuction
                            currentWallet={context.userState.currentWallet}
                            selectedAsset={req.body.selectedAsset}
                        />
                    }
                }))
        })
        ,

        [PATH.TX_FEE]: map(async (req) => {
            if (!req.body || !req.body.summary || !req.body.msg) {
                return redirect(PATH.HOME)
            }
            return authorizedRoute()(
                route({
                    getView: async (req, context) => {
                        let { default: TxFee } = await import('../views/payment/TxFee')
                        return <TxFee
                            wallets={context.userState.wallets}
                            currentWallet={context.userState.currentWallet}
                            summary={req.body.summary}
                            msg={req.body.msg}
                        />
                    }
                })
            )
        }),

        [PATH.SUMMARY]: map(async (req) => {
            if (!req.body || !req.body.summary) {
                return redirect(PATH.HOME)
            }
            return authorizedRoute()(
                route({
                    getView: async (req, context) => {
                        let { default: Summary } = await import('../views/payment/Summary')
                        return <Summary
                            summary={req.body.summary}
                            currentWallet={context.userState.currentWallet}
                        />
                    }
                })
            )
        }),

        [PATH.PAYMENT]: map(async (req) => {
            if (!req.body || !req.body.summary) {
                return redirect(PATH.HOME)
            }
            return authorizedRoute()(
                route({
                    getView: async (req, context) => {
                        let { default: Payment } = await import('../views/payment/Payment')
                        return <Payment
                            wallets={context.userState.wallets}
                            currentWallet={context.userState.currentWallet}
                            summary={req.body.summary}
                        />
                    }
                })
            )
        }),

        [PATH.PAID]: map(async (req) => {
            if (!req.body || !req.body.summary) {
                return redirect(PATH.HOME)
            }
            return authorizedRoute()(
                route({
                    getView: async (req, _) => {
                        let { default: Paid } = await import('../views/payment/Paid')
                        return <Paid summary={req.body.summary} />
                    }
                })
            )
        }),

        [PATH.AUCTIONS]: map(async () => {
            //TODO: Redirect to PATH FEATURED_AUCTIONS when Featured Auction api is ready
            //return redirect(PATH.FEATURED_AUCTIONS)
            return redirect(PATH.LISTED_AUCTIONS)

        }),

        [PATH.FEATURED_AUCTIONS]: route({
            getView: async (req, context) => {
                let { default: FeaturedAuctions } = await import('../views/auction/FeaturedAuctions')
                return <FeaturedAuctions
                    setLayoutWrapperTop={context.setLayoutWrapperTop}
                    currentWallet={context.userState.currentWallet}
                    windowWidth={context.windowWidth}
                    pageNo={req.params.p}
                />
            }
        }),

        [PATH.FEATURED_AUCTIONS_SEARCH]: map(async (req) => {
            if (req.params.q === undefined || req.params.q === "") {
                return redirect(PATH.FEATURED_AUCTIONS)
            }
            return route({
                getView: async (req, context) => {
                    let { default: FeaturedAuctions } = await import('../views/auction/FeaturedAuctions')
                    return <FeaturedAuctions
                        setLayoutWrapperTop={context.setLayoutWrapperTop}
                        currentWallet={context.userState.currentWallet}
                        windowWidth={context.windowWidth}
                        pageNo={req.params.p}
                        query={req.params.q}
                    />
                }
            })
        }),

        [PATH.LISTED_AUCTIONS]: route({
            getView: async (req, context) => {
                let { default: ListedAuctions } = await import('../views/auction/ListedAuctions')
                return <ListedAuctions
                    setLayoutWrapperTop={context.setLayoutWrapperTop}
                    currentWallet={context.userState.currentWallet}
                    windowWidth={context.windowWidth}
                    pageNo={req.params.p}
                />
            }
        }),

        [PATH.LISTED_AUCTIONS_SEARCH]: map(async (req) => {
            if (req.params.q === undefined || req.params.q === "") {
                return redirect(PATH.LISTED_AUCTIONS)
            }
            return route({
                getView: async (req, context) => {
                    let { default: ListedAuctions } = await import('../views/auction/ListedAuctions')
                    return <ListedAuctions
                        setLayoutWrapperTop={context.setLayoutWrapperTop}
                        currentWallet={context.userState.currentWallet}
                        windowWidth={context.windowWidth}
                        pageNo={req.params.p}
                        query={req.params.q}
                    />
                }
            })
        }),

        [PATH.MY_AUCTIONS]: map(async () => {
            return authorizedRoute()(
                redirect(PATH.MY_BIDDING_AUCTIONS)
            )
        }),

        [PATH.MY_BIDDING_AUCTIONS]: authorizedRoute()(
            route({
                getView: async (req, context) => {
                    let { default: MyBiddingAuctions } = await import('../views/auction/MyBiddingAuctions')
                    return <MyBiddingAuctions
                        setLayoutWrapperTop={context.setLayoutWrapperTop}
                        currentWallet={context.userState.currentWallet}
                        windowWidth={context.windowWidth}
                        pageNo={req.params.p}
                    />
                }
            })
        ),

        [PATH.MY_BIDDING_AUCTIONS_SEARCH]: map(async (req) => {
            if (req.params.q === undefined || req.params.q === "") {
                return authorizedRoute()(
                    redirect(PATH.MY_BIDDING_AUCTIONS)
                )
            }
            return authorizedRoute()(
                route({
                    getView: async (req, context) => {
                        let { default: MyBiddingAuctions } = await import('../views/auction/MyBiddingAuctions')

                        return <MyBiddingAuctions
                            setLayoutWrapperTop={context.setLayoutWrapperTop}
                            currentWallet={context.userState.currentWallet}
                            windowWidth={context.windowWidth}
                            pageNo={req.params.p}
                            query={req.params.q}
                        />
                    }
                })
            )
        }),

        [PATH.MY_SELLING_AUCTIONS]: authorizedRoute()(
            route({
                getView: async (req, context) => {
                    let { default: MySellingAuctions } = await import('../views/auction/MySellingAuctions')
                    return <MySellingAuctions
                        setLayoutWrapperTop={context.setLayoutWrapperTop}
                        currentWallet={context.userState.currentWallet}
                        windowWidth={context.windowWidth}
                        pageNo={req.params.p}
                    />
                }
            })
        ),

        [PATH.MY_SELLING_AUCTIONS_SEARCH]: map(async (req) => {
            if (req.params.q === undefined || req.params.q === "") {
                return authorizedRoute()(
                    redirect(PATH.MY_SELLING_AUCTIONS)
                )
            }
            return authorizedRoute()(
                route({
                    getView: async (req, context) => {
                        let { default: MySellingAuctions } = await import('../views/auction/MySellingAuctions')

                        return <MySellingAuctions
                            setLayoutWrapperTop={context.setLayoutWrapperTop}
                            currentWallet={context.userState.currentWallet}
                            windowWidth={context.windowWidth}
                            pageNo={req.params.p}
                            query={req.params.q}
                        />
                    }
                })
            )
        }),

        [PATH.AUCTION_DETAIL]: route({
            getView: async (req, context) => {
                let { default: AuctionDetail } = await import('../views/auction/AuctionDetail')
                return <AuctionDetail
                    //setLayoutWrapperTop={context.setLayoutWrapperTop}
                    currentWallet={context.userState.currentWallet}
                    currentUser={context.userState.currentUser}
                    //windowWidth={context.windowWidth}
                    pageNo={req.params.p}
                    id={req.params.id}
                />
            }
        }),

        [PATH.MY_FORGE]: authorizedRoute()(
            route({
                getView: async (req, context) => {
                    let { default: MyForge } = await import('../views/forge/MyForge')
                    return <MyForge
                        setLayoutWrapperTop={context.setLayoutWrapperTop}
                        currentWallet={context.userState.currentWallet}
                        windowWidth={context.windowWidth}
                        forgeId={req.params.f}
                        pageNo={req.params.p}
                    />
                }
            })
        ),

        [PATH.MY_FORGE_SEARCH]: map(async (req) => {
            if (req.params.q === undefined || req.params.q === "") {
                return authorizedRoute()(
                    redirect(PATH.MY_FORGE)
                )
            }
            return authorizedRoute()(
                route({
                    getView: async (req, context) => {
                        let { default: MyForge } = await import('../views/forge/MyForge')
                        return <MyForge
                            setLayoutWrapperTop={context.setLayoutWrapperTop}
                            currentWallet={context.userState.currentWallet}
                            windowWidth={context.windowWidth}
                            forgeId={req.params.f}
                            pageNo={req.params.p}
                            query={req.params.q}
                        />
                    }
                })
            )
        }),

        [PATH.ALREADY_SOLD]: authorizedRoute()(
            route({
                getView: async (req, context) => {
                    let { default: AlreadySold } = await import('../views/forge/AlreadySold')
                    return <AlreadySold
                        setLayoutWrapperTop={context.setLayoutWrapperTop}
                        currentWallet={context.userState.currentWallet}
                        windowWidth={context.windowWidth}
                        forgeId={req.params.f}
                        pageNo={req.params.p}
                    />
                }
            })
        ),

        [PATH.ALREADY_SOLD_SEARCH]: map(async (req) => {
            if (req.params.q === undefined || req.params.q === "") {
                return authorizedRoute()(
                    redirect(PATH.ALREADY_SOLD)
                )
            }
            return authorizedRoute()(
                route({
                    getView: async (req, context) => {
                        let { default: AlreadySold } = await import('../views/forge/AlreadySold')
                        return <AlreadySold
                            setLayoutWrapperTop={context.setLayoutWrapperTop}
                            currentWallet={context.userState.currentWallet}
                            windowWidth={context.windowWidth}
                            forgeId={req.params.f}
                            pageNo={req.params.p}
                            query={req.params.q}
                        />
                    }
                })
            )
        }),

        [PATH.CREATE_FORGE]: authorizedRoute()(
            route({
                getView: async (_, context) => {
                    let { default: CreateForge } = await import('../views/forge/CreateForge')
                    return <CreateForge
                        currentWallet={context.userState.currentWallet}
                    />
                }
            })
        ),

        [PATH.CREATE_ASSETS]: authorizedRoute()(
            route({
                getView: async (_, context) => {
                    let { default: CreateAssets } = await import('../views/forge/CreateAssets')
                    return <CreateAssets
                        currentWallet={context.userState.currentWallet}
                    />
                }
            })
        ),

        [PATH.CREATE_KIND]: authorizedRoute()(
            route({
                getView: async (_, context) => {
                    let { default: CreateKind } = await import('../views/forge/CreateKind')
                    return <CreateKind
                        windowWidth={context.windowWidth}
                        currentWallet={context.userState.currentWallet}
                    />
                }
            })
        ),

        [PATH.MINT_ASSETS]: authorizedRoute()(
            route({
                getView: async (req, context) => {
                    let { default: MintAssets } = await import('../views/forge/MintAssets')
                    return <MintAssets
                        setLayoutWrapperTop={context.setLayoutWrapperTop}
                        windowWidth={context.windowWidth}
                        currentWallet={context.userState.currentWallet}
                        forgeId={req.params.f}
                    />
                }
            })
        ),

        [PATH.MINT_ASSETS_SEARCH]: map(async (req) => {
            if (req.params.q === undefined || req.params.q === "") {
                return authorizedRoute()(
                    redirect(PATH.MINT_ASSETS)
                )
            }
            return authorizedRoute()(
                route({
                    getView: async (req, context) => {
                        let { default: MintAssets } = await import('../views/forge/MintAssets')

                        return <MintAssets
                            setLayoutWrapperTop={context.setLayoutWrapperTop}
                            currentWallet={context.userState.currentWallet}
                            windowWidth={context.windowWidth}
                            forgeId={req.params.f}
                            query={req.params.q}
                        />
                    }
                })
            )
        }),

        [PATH.MINT_ASSETS_HASH]: authorizedRoute()(
            route({
                getView: async (_, context) => {
                    let { default: MintAssetsHash } = await import('../views/forge/MintAssetsHash')
                    return <MintAssetsHash
                        currentWallet={context.userState.currentWallet}
                    />
                }
            })
        ),

        [PATH.MINT_ASSETS_JSON]: authorizedRoute()(
            route({
                getView: async (_, context) => {
                    let { default: MintAssetsJson } = await import('../views/forge/MintAssetsJson')
                    return <MintAssetsJson
                        currentWallet={context.userState.currentWallet}
                    />
                }
            })
        ),

        [PATH.MY_ASSETS]: authorizedRoute()(
            redirect(PATH.MY_ASSETS_OWNED)
        ),

        [PATH.ASSET_DETAIL]: route({
            getView: async (req, context) => {
                let { default: AssetDetail } = await import('../views/asset/AssetDetail')
                return <AssetDetail
                    //setLayoutWrapperTop={context.setLayoutWrapperTop}
                    //windowWidth={context.windowWidth}
                    asset={req.body && req.body.asset}
                    id={req.params.id}
                />
            }
        }),

        [PATH.MY_ASSETS_OWNED]: authorizedRoute()(
            route({
                getView: async (req, context) => {
                    let { default: MyAssetsOwned } = await import('../views/asset/MyAssetsOwned')

                    return <MyAssetsOwned
                        setLayoutWrapperTop={context.setLayoutWrapperTop}
                        currentWallet={context.userState.currentWallet}
                        windowWidth={context.windowWidth}
                        pageNo={req.params.p}
                    />
                }
            })
        ),

        [PATH.MY_ASSETS_OWNED_SEARCH]: map(async (req) => {
            if (req.params.q === undefined || req.params.q === "") {
                return authorizedRoute()(
                    redirect(PATH.MY_ASSETS_OWNED)
                )
            }
            return authorizedRoute()(
                route({
                    getView: async (req, context) => {
                        let { default: MyAssetsOwned } = await import('../views/asset/MyAssetsOwned')

                        return <MyAssetsOwned
                            setLayoutWrapperTop={context.setLayoutWrapperTop}
                            currentWallet={context.userState.currentWallet}
                            windowWidth={context.windowWidth}
                            pageNo={req.params.p}
                            query={req.params.q}
                        />
                    }
                })
            )
        }),

        [PATH.MY_ASSETS_HISTORY]: authorizedRoute()(
            route({
                getView: async (req, context) => {
                    let { default: MyAssetsHistory } = await import('../views/asset/MyAssetsHistory')
                    return <MyAssetsHistory
                        setLayoutWrapperTop={context.setLayoutWrapperTop}
                        currentWallet={context.userState.currentWallet}
                        windowWidth={context.windowWidth}
                        pageNo={req.params.p}
                    />
                }
            })
        ),

        [PATH.MY_ASSETS_HISTORY_SEARCH]: map(async (req) => {
            if (req.params.q === undefined || req.params.q === "") {
                return authorizedRoute()(
                    redirect(PATH.MY_ASSETS_HISTORY)
                )
            }
            return authorizedRoute()(
                route({
                    getView: async (req, context) => {
                        let { default: MyAssetsHistory } = await import('../views/asset/MyAssetsHistory')

                        return <MyAssetsHistory
                            setLayoutWrapperTop={context.setLayoutWrapperTop}
                            currentWallet={context.userState.currentWallet}
                            windowWidth={context.windowWidth}
                            pageNo={req.params.p}
                            query={req.params.q}
                        />
                    }
                })
            )
        }),

        [PATH.TEST_TRANSACTION]: devOnly()(
            route({
                getView: async (_, context) => {
                    let { default: TestTransaction } = await import('../views/test/TestTransaction')
                    return <TestTransaction
                        agenceWasm={context.wasm}
                        currentWallet={context.userState.currentWallet}
                        userService={context.userService}
                        agenceAccountService={context.agenceAccountService}
                    />
                }
            })
        ),

        [PATH.TEST_TRANSACTION]: devOnly()({
            getView: async (_, context) => {
                let { default: TestTransaction } = await import('../views/test/TestTransaction')
                return <TestTransaction agenceWasm={context.wasm} />
            }
        }),

        [PATH.KIT]: route({
            getView: async () => {
                let { default: Kit } = await import('../views/kit/Kit')
                return <Kit />
            }
        }),

        [PATH.NOT_FOUND]: route({ getView: () => import('../views/NotFound') }),
    })
)

