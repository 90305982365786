import React from 'react';
import { Popper } from 'react-popper';
import { WarningRounded, Error } from '@material-ui/icons';
import { COLOR } from '../../utils/type';

const HelpIcon = <Error className="icon tooltip-icon help-icon" />
const ErrorIcon = <WarningRounded className="icon tooltip-icon error-icon" />

export const Tooltip = (props) => {
    const { placement, helpMessage, errorMessage, hasHelper, hasError } = props
    const tipColor = errorMessage ? COLOR.DANGER : helpMessage ? COLOR.PRIMARY : ''
    const tipIcon = hasError ? ErrorIcon : hasHelper ? HelpIcon : ''
    const message = errorMessage || helpMessage

    if (!message) {
        return null
    }

    return (
        <Popper
            placement={placement ? placement : 'right'}
            modifiers={[{
                name: 'offset',
                options: { offset: [0, 12] }
            }, {
                name: 'flip',
                options: { fallbackPlacements: ['right', 'bottom', 'top'] }
            }]}
        >
            {({ ref, style, placement }) => (
                <div
                    className='tooltip-container'
                    ref={ref}
                    style={style}
                    data-placement={placement}
                >
                    <div className={`tooltip input-tooltip ${tipColor} ${placement} ${tipIcon ? "" : "hidden"}`}>
                        {tipIcon}
                        {message}
                    </div >
                </div >
            )}
        </Popper >
    )
}