import React, { useState, useContext, useEffect } from 'react'
import { useAgence } from './agence'
import { auctionService } from '../../api/auctions'

const AuctionsContext = React.createContext({})
const useAuctions = () => {
    return useContext(AuctionsContext)
}

const AuctionsProvider = ({ children }) => {
    const { state } = useAgence()
    const [auctionServiceReady, setServiceReady] = useState(false)
    const { api, apiReady } = state

    useEffect(() => {
        if (!apiReady || !api) {
            return
        }

        auctionService.setup(api)
        setServiceReady(true)
    }, [api, apiReady])

    return (
        <AuctionsContext.Provider value={{ auctionServiceReady, auctionService }}>
            {children}
        </AuctionsContext.Provider>
    )
}

export { useAuctions, AuctionsProvider }
