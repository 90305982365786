import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DROPDOWN_TYPE } from '../../utils/type';
import Dropdown from '../kit/dropdown';
import i18nConfig, { FALLBACK_LNG } from '../../i18n/config';

export default function LanguageSelect() {
    const { i18n } = useTranslation()
    const [currentLng, setCurrentLng] = useState(
        i18nConfig.find(lng => lng.code === i18n.language) ||
        FALLBACK_LNG)

    const changeLanguage = useCallback((lng) => {
        if (lng) {
            i18n.changeLanguage(lng.code)
            setCurrentLng(lng)
        }
    }, [i18n])

    return (
        <Dropdown
            dropdownType={DROPDOWN_TYPE.OVAL}
            items={i18nConfig.map(lng => lng.display)}
            defaultSelected={i18nConfig.indexOf(currentLng)}
            onSelect={sortIndex => changeLanguage(i18nConfig[sortIndex])}
            placement={'top'}
        />
    )
}
