import React, { Suspense, useState, useEffect } from 'react';
import './App.scss';
import './styles/views/Shared.scss';
import { Router, View } from 'react-navi';
import { useTranslation } from 'react-i18next';
import routes from './routes/routes';
import moment from 'moment';

import { AgenceProvider } from './components/providers/agence';
import { AccountsProvider } from './components/providers/account';
import { UsersProvider, useUsers } from './components/providers/users';
import { AssetsProvider } from './components/providers/assets';
import { AuctionsProvider } from './components/providers/auctions';
import { ForgesProvider } from './components/providers/forges';
import Loading from './components/shared/loading';
import ErrorBoundary from './components/shared/errorBoundary';
import i18nConfig, { FALLBACK_LNG } from './i18n/config';
import config from './config';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

function App() {

    const I18nSettings = ({ children }) => {
        const { i18n } = useTranslation()

        const locale = (
            i18nConfig.find(lng => lng.code === i18n.language) ||
            FALLBACK_LNG).momentLocale
        moment.locale(locale)
        moment.duration().locale(locale)

        return <>
            {children}
        </>
    }

    //#HACK
    const RouterWrapper = ({ children }) => {
        const [windowWidth, setWindowWidth] = useState(window.innerWidth)
        const [layoutWrapperTop, setLayoutWrapperTop] = useState(0)

        const { state } = useUsers()

        useEffect(() => {
            const onReSize = () => setWindowWidth(window.innerWidth)

            window.addEventListener('resize', onReSize)
            return () => {
                window.removeEventListener('resize', onReSize)
            }
        }, [])

        if (!state.userServiceReady) {
            return <Loading />
        }

        return (
            <ErrorBoundary>
                <Router routes={routes} context={{
                    layoutWrapperTop: layoutWrapperTop,
                    setLayoutWrapperTop: setLayoutWrapperTop,
                    userState: state,
                    windowWidth
                }}>
                    {children}
                </Router>
            </ErrorBoundary>
        )
    }

    return (
        <div className="App">
            <AgenceProvider url={config.NODE_URL}>
                <AccountsProvider>
                    <UsersProvider>
                        <AssetsProvider>
                            <AuctionsProvider>
                                <ForgesProvider>
                                    <Suspense fallback={null}>
                                        <I18nSettings>
                                            <RouterWrapper>
                                                <View />
                                            </RouterWrapper>
                                        </I18nSettings>
                                    </Suspense>
                                </ForgesProvider>
                            </AuctionsProvider>
                        </AssetsProvider>
                    </UsersProvider>
                </AccountsProvider>
            </AgenceProvider>
        </div >
    )
}

export default App