import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from "react-navi";
import { Button } from '@material-ui/core';
import { IMAGE_PATH, PATH } from '../../utils/type';
import { useTranslation } from 'react-i18next';

export default function ErrorPage(props) {
    const { onRefresh } = props
    const { t } = useTranslation()

    return (
        <div className="no-result-container">
            <div className="no-result-img-container">
                <img className="no-result-img" src={IMAGE_PATH.ARMOR_ERROR} alt={t("logo")} />
                <span className="no-result-img-text">
                    {t("oops! Something is wrong here")}
                </span>
            </div>
            <div className="no-result-text-container">
                <Button className="link-text" onClick={onRefresh}>
                    {t("Refresh")}
                </Button>
                <span> or </span>
                <Link href={PATH.HOME} className="link-text">
                    {t("head back to home")}
                </Link>
            </div>
        </div >
    )
}

ErrorPage.propTypes = {
    onRefresh: PropTypes.func,
}