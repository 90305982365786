// import { handleB64Resp } from '../utils/common'

export const agenceAccountService = {
    setup(api) {
        this.api = api
    },

    async getAgenceAccount(address) {
        const account_info = await this.api.query.system.account(address)
        var accountData = { ...account_info }
        accountData.address = address
        //#HACK
        accountData.freeBalance = account_info.data.free.toString()

        return accountData
    },

    async subscribeAgenceAccount(address, callback) {
        const unsub = await this.api.query.system.account(address, (account_info) => {
            var accountData = { ...account_info }
            accountData.address = address
            //#HACK
            accountData.freeBalance = account_info.data.free.toString()

            callback(accountData)
        })

        return unsub
    }

}
