import React, { useState, useContext, useEffect } from 'react'
import { useAgence } from './agence'
import { agenceAccountService } from '../../api/agenceAccount'
import Keyring from '@polkadot/keyring'

const keyPairType = 'sr25519'

const AccountsContext = React.createContext({})
const useAccounts = () => {
    return useContext(AccountsContext)
}

const AccountsProvider = ({ children }) => {

    // const [state, setState] = useState({ addressBook: {} })
    const [keyring, setKeyring] = useState(null)
    const [accountServiceReady, setServiceReady] = useState(false)
    // const [unsubs, setUnsubs] = useState({})
    const { state } = useAgence()
    const { api, apiReady, node_properties } = state

    useEffect(() => {
        if (!apiReady || !api) {
            return
        }

        const keyringInst = new Keyring({ type: keyPairType })
        keyringInst.setSS58Format(node_properties.ss58Format)
        setKeyring(keyringInst)

        agenceAccountService.setup(api)
        setServiceReady(true)

    }, [api, apiReady, node_properties])

    return (
        <AccountsContext.Provider value={{ accountServiceReady, keyring, setKeyring, agenceAccountService }}>
            {children}
        </AccountsContext.Provider>
    )
}

export { useAccounts, AccountsProvider }
