import React from 'react'
import '../../styles/views/Layout.scss'
import { useCurrentRoute } from 'react-navi'
import Topbar from './Topbar'
import Footer from './Footer'
import { useUsers } from '../../components/providers/users'

export default function Layout(props) {
    const { currentUser, wallets, currentWallet, layoutWrapperTop, children } = props
    const { userService } = useUsers()
    const route = useCurrentRoute()
    const pathname = route.url.pathname

    return (
        <div className="layout">
            <Topbar
                userService={userService}
                wallets={wallets}
                currentPathname={pathname}
                currentUser={currentUser}
                currentWallet={currentWallet}
            />
            <div className="layout-wrapper" style={{ top: `${layoutWrapperTop}px` }}>
                {children}
            </div>
            <Footer />
        </div>
    )
}